import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Box, IconButton, Typography, Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { type ReactNode } from 'react'

interface IGenericDialog {
  open: boolean
  onClose: () => void
  isLoading: boolean
  title: string
  children: ReactNode
}

export const GenericDialog = ({
  open,
  onClose,
  isLoading,
  title,
  children
}: IGenericDialog): JSX.Element => {
  const isMobile = useMobileBreakpoints()

  const handleClose = (_event: any, reason: string): void => {
    if (reason === 'backdropClick') return
    onClose()
  }

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box >
          <Box display='flex' justifyContent='flex-end'>
            <IconButton onClick={onClose} disabled={isLoading}>
              <CloseIcon color='primary' />
            </IconButton>
          </Box>

          <Box px={4} pb={4}>
            <DialogIcon iconType='warning' />

            <Typography
              color='#010B0E'
              fontSize={isMobile ? 18 : 24}
              fontWeight={600}
              textAlign='center'
              mb={3}
            >
              {title}
            </Typography>
            {children}
          </Box>
        </Box>
      </Dialog>
    </div>
  )
}

interface IDialogIcon {
  iconType: string
}
const DialogIcon = ({ iconType }: IDialogIcon): JSX.Element => {
  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      gap={1}
      borderRadius='4px'
      alignItems='center'
      mb={3}
    >
      {iconType === 'warning' && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderRadius='50%'
          bgcolor='#FEF5EF'
          p={2}
        >
          <ErrorOutlineIcon sx={{ color: '#FB8C3C', fontSize: 40 }} />
        </Box>
      )}
    </Box>
  )
}
