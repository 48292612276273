import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Dialog, Link, List, ListItem, Stack, Typography } from '@mui/material'
import type React from 'react'

interface EmailNotFoundDialogProps {
    open: boolean
    close: () => void
}

export const EmailNotFoundDialog: React.FC<EmailNotFoundDialogProps> = (props: EmailNotFoundDialogProps) => {
    return (
        <Dialog fullWidth open={props.open} onClose={() => { props.close() }}>
            <Box p={3} alignItems="center" justifyContent="center" >
                <Stack mt={4} direction="column" gap={1} alignItems="center" justifyContent="center">
                    <Box display="flex" justifyContent="right">
                        <Avatar sx={{ bgcolor: '#F3FBFD', width: 50, height: 50, p: 1 }}>
                            <EmailIcon fontSize='large' sx={{ color: '#B5B7E6' }} />
                        </Avatar>
                        <ErrorIcon sx={{ position: 'absolute', top: 90 }} color='error'></ErrorIcon>
                    </Box>
                    <Typography fontSize={24} color="#010B0E" fontWeight={600} textAlign="center">Email Address Not Found</Typography>
                    <Typography fontSize={16} color="#010B0E" textAlign="center">The email address provided is not registered with MVU</Typography>
                    <Typography fontSize={16} color="#010B0E" textAlign="center">Please try again with another email address or contact us at:</Typography>
                </Stack>
                <List sx={{
                    listStyleType: 'disc',
                    ml: 10,
                    p: 1,
                    fontSize: 14
                }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={14}>(844) 341-6469</Typography>
                    </ListItem>
                </List>
                <Typography fontSize={12}>**If you&apos;re looking to pay your bill, you can navigate back to the <Link
                    href="/"
                    target="_self"
                    rel="noreferrer"
                    color='#1513C3'
                    style={{ textDecoration: 'none' }}
                >
                    homepage
                </Link> and click on the “One-Time Payment” button.</Typography>
            </Box>
        </Dialog>
    )
}
