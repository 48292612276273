import PaymentsIcon from '@mui/icons-material/Payments'
import {
  Box,
  Grid,
  Link,
  Radio,
  Typography,
  styled
} from '@mui/material'
import { InvoiceCloudFrame, MButton, ProgressLoaders, Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { MESSAGES, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { isNil, last } from 'lodash'

export interface IUserValue {
  name?: string
  officeNumber?: string
  mobileNumber?: string
  mailingAddress?: string
  email?: string
  balance?: string
  alteredEmail?: string
  serviceAddress?: string[]
}

export const Account: React.FC = () => {
  const { account, billing, isAdmin, userEmail, currentAccount, getAccount, handleCurrentAccount, getBilling } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [active, setActive] = useState<number>(0)
  const [userValue, setUserValue] = useState<IUserValue>()
  const [defaultAccountId, setDefaultAccountId] = useState<any>(null)
  const isMobile = useMobileBreakpoints()
  const billURL = billing?.billing[billing.billing.length - 1]?.billDisplayURL ?? null
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (account) {
      const serviceAddresses = account?.myAccount?.serviceAddresses?.map(item => item.serviceAddress)
      setUserValue({
        name: account?.myAccount?.personName,
        officeNumber: account?.myAccount?.alternateNumber,
        mobileNumber: account?.myAccount?.mobileNumber,
        mailingAddress: `${account?.myAccount?.mailingAddress?.addressLine1}, ${account?.myAccount?.mailingAddress?.city}, ${account?.myAccount?.mailingAddress?.state} ${account?.myAccount?.mailingAddress?.postal}`,
        email: account?.myAccount?.email,
        balance:
          account?.myAccount?.accountBalance &&
          formatValue(account?.myAccount.accountBalance),
        alteredEmail: 'ND',
        serviceAddress: serviceAddresses
      })
    }
    if (!billing) {
      void getBilling({
        AccessToken: authToken,
        accountId: account?.myAccount.accountId,
        startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        admin: isAdmin,
        email: userEmail
      })
    }
  }, [account, billing])

  useEffect(() => {
    if (account && !defaultAccountId) {
      setDefaultAccountId(account?.myAccount.defaultAccountId)
    }
  }, [account, defaultAccountId])


  const handleCheckBox = async (item: any): Promise<void> => {
    try {
      const body: any = {
        AccessToken: authToken,
        accountId: account?.myAccount.accountId,
        admin: isAdmin,
        email: userEmail
      }
      body.updates = [
        {
          object: 'DEFAULTACCOUNT',
          value: item.accountId
        }
      ]
      await APIs.updateUser(body)
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
        variant: 'success',
        autoHideDuration: 3000
      })
      setDefaultAccountId(item.accountId)
      handleCurrentAccount({ accountId: item.accountId ?? '', premiseId: item?.premiseId ?? '' })
      void getAccount({
        AccessToken: authToken,
        accountId: item.accountId
      })
    } catch (e) {
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }
  const handleDownloadBill = (): void => {
    const billDisplayURL = last(billing?.billing)?.billDisplayURL
    if (!isNil(billDisplayURL) && billDisplayURL !== '') {
      window.open(billDisplayURL, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  return (
    <>
      {isMobile && <QuickLinks />}
      {account !== null
        ? <Grid container spacing={2} mt={0}>
          <Grid item sm={12} md={8}>
            <Wrapper gap={2}>
              <BoxWrapper>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                  <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                    General Information
                  </Typography>
                </Box>

                {(userValue != null)
                  ? <GeneralInformation userValue={userValue} />
                  : <ProgressLoaders height="100%" />}


              </BoxWrapper>

              <BoxWrapper>
                <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                  Service Address
                </Typography>
                <Box my={2}>
                  <Text color="#4d515f" fontSize="16px" inline="true">
                    Please select a service address from the list below to be
                    displayed by default when you log in to your account portal.
                    If you do not see your service address listed below, please
                    contact &nbsp;
                  </Text>
                  <Text color="#1513C3" fontSize="16px" inline="true">
                    844-341-6469
                  </Text>
                  <Text color="#4d515f" fontSize="16px" inline="true">
                    .
                  </Text>
                </Box>

                {account?.myAccount?.serviceAddresses?.map(
                  (item: any, index: number) => (
                    <Box
                      key={index}
                      border='1px solid #E5E5F6'
                      onClick={() => { setActive(0) }}
                    >
                      <Box
                        p={1}
                        display="flex"
                        alignItems="center"
                        bgcolor={active === 0 ? '#F9F9FD' : '#ffffff'}
                      >
                        <Radio
                          onChange={async () => { await handleCheckBox(item) }}
                          checked={item?.accountId === defaultAccountId && true}
                        />
                        <Box>
                          <Typography fontSize={10} color="#5D6365">
                            ACCOUNT NUMBER
                          </Typography>
                          <Typography fontSize={16} color="#010B0E">
                            {item?.accountId}
                          </Typography>
                        </Box>
                      </Box>
                      {isMobile
                        ? <Box p={1}>
                          <Box display="flex" flexDirection="column" mb={2}>
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              PROPERTY ADDRESS
                            </Typography>
                            <Typography flex={1} color="#343C3E" fontSize={14}>
                              {item?.serviceAddress}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" >
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              OLD ACCOUNT NUMBER
                            </Typography>
                            <Typography flex={1} color="#343C3E" fontSize={14}>
                              {item?.legacyAcctNumber}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" >
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              Meter Number
                            </Typography>
                            <Typography flex={1} color="#343C3E" fontSize={14}>
                              {item?.meterNumber}
                            </Typography>
                          </Box>
                        </Box>
                        : <Box m={1}>
                          <Box display="flex" p={1} borderBottom='1px solid #EFEFFA'>
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              PROPERTY ADDRESS (1)
                            </Typography>
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              OLD ACCOUNT NUMBER
                            </Typography>
                            <Typography flex={1} color="#5D6365" fontSize={10}>
                              METER NUMBER
                            </Typography>
                          </Box>
                          <Box display="flex" p={1}>
                            <Typography flex={1} color="#343C3E" fontSize={16}>
                              {item?.serviceAddress}
                            </Typography>
                            <Typography flex={1} color="#343C3E" fontSize={16}>
                              {item?.legacyAcctNumber}
                            </Typography>
                            <Typography flex={1} color="#343C3E" fontSize={16}>
                              {item?.meterNumber}
                            </Typography>
                          </Box>
                        </Box>}
                    </Box>
                  )
                )}
              </BoxWrapper>
            </Wrapper>
          </Grid>
          <Grid item sm={12} md={4}>
            <BoxWrapper>
              <Box display="flex" alignItems="center" mb={3}>
                <PaymentsIcon fontSize='medium' sx={{ color: '#82C5EB' }} />
                <Typography ml={1} color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                  Current Bill Detail
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={3}
              >
                <Text color="#000000" fontSize="18px">
                  Total Due
                </Text>
                <Box
                  p="12px 16px"
                  sx={{ background: '#EEF3F6', borderRadius: '4px' }}
                >
                  <Text color="#010B0E" fontSize="24px" fontWeight="600">
                    {userValue?.balance}
                  </Text>
                </Box>
              </Box>
              <InvoiceCloudFrame page={1}>
                <MButton
                  fullWidth
                  rounded="true"
                  variant="contained"
                  texttransform='none'
                  size="large"
                >
                  Pay Bill
                </MButton>
              </InvoiceCloudFrame>
              <Typography color="#5D6365" fontSize={16} my={2} textAlign={isMobile ? 'center' : 'left'}>
                To download your invoice,&nbsp;
                <MButton
                  onClick={handleDownloadBill}
                  variant="text"
                  texttransform='none'
                  size="small"
                  bgcolor='none'
                  sx={{
                    color: billURL ? '#1513C3' : '#808080',
                    '&:hover': {
                      background: 'none'
                    }
                  }}
                >
                  Click Here.
                </MButton>
              </Typography>
              <Typography color="#5D6365" fontSize={14}>
                Did you know you can now pay your bill using Apple Pay, Google
                Pay, Venmo, or Paypal?
              </Typography>
              <Typography display="flex" color="#5D6365" fontSize={18} my={2} textAlign={isMobile ? 'center' : 'left'}>
                Need a Payment Plan? Contact us at
                <Link
                  ml={0.5}
                  href="tel:8443416469"
                  target="_blank"
                  rel="noreferrer"
                  color='#1513C3'
                  style={{ textDecoration: 'none' }}
                >
                  844-341-6469
                </Link>
                .
              </Typography>
            </BoxWrapper>
          </Grid>
        </Grid>
        : <Box mt={3}><ProgressLoaders height="100%" /></Box>}
    </>

  )
}

const GeneralInformation = ({ userValue }: { userValue: IUserValue }): JSX.Element => {
  return (
    <Grid borderRadius="4px" container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            NAME
          </Text>
          <Text color="#010B0E" fontSize="16px">
            {userValue?.name}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            HOME / OFFICE NUMBER
          </Text>
          <Text color="#010B0E" fontSize="16px">
            {userValue && (userValue.mobileNumber ?? userValue.officeNumber)}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            MAILING ADDRESS
          </Text>
          <Text color="#010B0E" fontSize="16px">
            {userValue?.mailingAddress}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            EMAIL ID
          </Text>
          <Text color="#010B0E" fontSize="16px">
            {userValue?.email}
          </Text>
        </Box>
      </Grid>
    </Grid>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const BoxWrapper = styled(Box)({
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '24px'
})
