import { Box, Grid, Typography } from '@mui/material'
import { ProgressLoaders } from 'components'
import { ROUTES_PATH, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import Chart from 'react-apexcharts'
import { Link } from 'react-router-dom'
import { defaultOptions, formatValue, screenType, useLocalStorage, useWindowSize } from 'utils'
import { type BillingData, type BillingSegmentData, type SQS } from 'utils/hooks/types'
import styles from './PowerUsage.module.scss'

interface DataState {
  kwh: {
    dates: string[]
    values: any
  }
  kw: {
    dates: string[]
    values: number[]
  }
  billing: {
    dates: string[]
    values: string[]
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const PowerUsage = () => {
  const { account, billing, usage, currentAccount, getBilling, isAdmin, userEmail } = useAuth()
  const [powerUsage, setPowerUsage] = useState(0)
  const initialState: DataState = {
    kwh: { dates: [], values: [] },
    kw: { dates: [], values: [] },
    billing: { dates: [], values: [] }
  }
  const { width } = useWindowSize()
  const [data, setData] = useState<DataState>(initialState)

  // billing
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [authToken] = useLocalStorage(USER_TOKEN, '')

  const billingDates: string[] = []
  const billingValues: string[] = []
  const kwhDates: any[] = []
  const kwhValues: any = {
    consumed: [],
    generated: [],
    net: []
  }
  const kwDates: any[] = []
  const kwValues: any[] = []

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === billing?.accountId) {
      return
    }
    void getBilling({
      AccessToken: authToken,
      accountId: currentAccount?.accountId ?? account?.myAccount.accountId,
      startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      admin: isAdmin,
      email: userEmail
    })
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])

  const cleanUsageData = (): void => {
    if (billing != null && Array.isArray(billing.billing)) {
      const sortedBillData = _.sortBy(billing.billing, 'readDate')
      sortedBillData.forEach(bill => {
        billingDates.push(dayjs(bill.readDate).format('MMM YY'))
        billingValues.push(bill.billAmount)
      })
    }

    if ((usage?.usage?.billData) != null) {
      const sortedUsageBillData = _.sortBy(usage?.usage?.billData, 'readDate')
      sortedUsageBillData.forEach((bill: BillingData) => {
        let totalConsumed = 0
        let totalGenerated = 0
        let totalNet = 0
        bill?.billSegmentData?.forEach((segment: BillingSegmentData) => {
          segment?.sq?.sqs?.forEach((sqs: SQS) => {
            if (sqs.uom?.toUpperCase() === 'KWH') {
              switch (sqs.sqi) {
                case 'CONSUMED':
                  totalConsumed += parseFloat(sqs?.billSq ?? '0')
                  break
                case 'GEN':
                  totalGenerated += parseFloat(sqs?.billSq ?? '0')
                  break
                case 'NET':
                  totalNet += parseFloat(sqs?.billSq ?? '0')
                  break
                default:
                  break
              }
            }
          })
        })

        kwhValues.consumed.push(totalConsumed)
        kwhValues.generated.push(totalGenerated)
        kwhValues.net.push(totalNet)

        const d = bill.readDate.slice(0, 10)
        const newDate = dayjs(d).format('MMM')
        kwhDates.push(`${newDate} ${d.slice(2, 4)}`)
      })
    }
    if ((usage?.usage?.usageHistory) != null && usage?.usage?.usageHistory.length > 0) {
      usage?.usage?.usageHistory.forEach((usageH: any) => {
        if (usageH?.uom.toUpperCase() === 'KW') {
          const date = new Date()
          usageH.usageData?.filter((item: any) => new Date(item.period) <= date)
            .forEach((item: any) => {
              kwDates.push(dayjs(item.period).format('MMM YY'))
              kwValues.push(Math.round(item.value))
            })
        }
      })
    }
    if (
      (account?.myAccount?.isNEM === true) &&
      kwhValues.generated.length === kwhValues.consumed.length &&
      kwhValues.net.length === 0
    ) {
      for (let i = 0; i < kwhValues.consumed.length; i++) {
        const net = kwhValues.consumed[i] + kwhValues.generated[i]
        kwhValues.net.push(formatValue(net, true, false, false))
      }
    }

    setData({
      billing: {
        dates: billingDates,
        values: billingValues
      },
      kwh: {
        dates: kwhDates,
        values: kwhValues
      },
      kw: {
        dates: kwDates,
        values: kwValues
      }
    })
  }


  useEffect(() => {
    if (usage !== null || billing !== null) {
      cleanUsageData()
    }
  }, [usage, billing])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getKWH = () => {
    if (account?.myAccount?.isNEM === true) {
      return [
        {
          name: 'Net Usage',
          data: data?.kwh?.values?.net ?? []
        },
        {
          name: 'Consumed',
          data: data?.kwh?.values?.consumed ?? []
        },
        {
          name: 'Generated',
          data: data?.kwh?.values?.generated ?? []
        }
      ]
    }
    return [
      {
        name: 'Usage',
        data: data?.kwh?.values?.consumed ?? []
      }
    ]
  }

  const options: any = [
    {
      ...defaultOptions,
      chart: {
        toolbar: {
          export: {
            csv: {
              filename: 'Monthly Usage Chart (kwh)',
              valueFormatter(y: any) {
                return formatValue(y, false, false, false, false)
              }
            }
          }
        },
        animations: {
          enabled: true
        }
      },
      series: getKWH(),
      xaxis: {
        categories: data?.kwh?.dates,
        labels: {
          show: true,
          formatter(val: any) {
            if (data.kwh.dates.length === 0) return ''
            return val
          }
        }
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          show: true,
          formatter(val: any) {
            return formatValue(val, false, false)
          }
        }
      },
      tooltip: {
        y: {
          formatter(val: any) {
            return formatValue(val, false, false)
          }
        }
      },
      option: {
        chart: {
          id: 'option-1'
        }
      }
    },
    {
      ...defaultOptions,
      chart: {
        toolbar: {
          export: {
            csv: {
              filename: 'Monthly Billing Chart',
              valueFormatter(y: any) {
                return formatValue(y, true, true, false, false)
              }
            }
          }
        },
        animations: {
          enabled: true
        }
      },
      series: [
        {
          name: 'Monthly Bill',
          data: data?.billing?.values
        }
      ],
      xaxis: {
        categories: data.billing.dates,
        labels: {
          show: true,
          formatter(val: any) {
            if (data.billing.dates.length === 0) return ''
            return val
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          formatter(val: any) {
            return formatValue(val, true, true)
          }
        }
      },
      tooltip: {
        y: {
          formatter(val: any) {
            return formatValue(val, true, true)
          }
        }
      },
      option: {
        chart: {
          id: 'option-2'
        }
      }
    },
    {
      ...defaultOptions,
      chart: {
        toolbar: {
          export: {
            csv: {
              filename: 'Monthly Peak Demand (kW)',
              valueFormatter(y: any) {
                return formatValue(y, false, false, false, false)
              }
            }
          }
        },
        animations: {
          enabled: true
        }
      },
      series: [
        {
          name: 'Usage KW',
          data: data.kw.values
        }
      ],
      xaxis: {
        categories: data?.kw?.dates,
        labels: {
          show: true,
          formatter(val: any) {
            if (data.kw.dates.length === 0) return ''
            return val
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          formatter(val: any) {
            if (data.kw.dates.length === 0) return ''
            return val
          }
        }
      },
      tooltip: {
        y: {
          formatter(val: any) {
            return formatValue(val, false, false)
          }
        }
      },
      option: {
        chart: {
          id: 'option-3'
        }
      }
    }
  ]

  const list = [{ title: 'kWh' }, { title: '$$$' }, { title: 'kW' }]

  const graphTitle = useMemo(() => {
    let title = 'Monthly Usage'

    if (powerUsage === 1) {
      title = 'Monthly Charges'
    } else if (powerUsage === 2) {
      title = 'Monthly Peak Demand'
    }

    return title
  }, [powerUsage])

  if (usage === null || billing === null) {
    return (
      <Grid>
        <ProgressLoaders height="310px" />
      </Grid>
    )
  }

  return (
    <Grid className={styles.PowerUsage}>
      <Box className={styles.PowerUsageInner}>
        <Box className={styles.PowerUsageHeader}>
          {screenType(width, 'sm') ? <h5>{graphTitle}</h5> : <h3>{graphTitle}</h3>}
          <Box className={styles.PowerUsageHeaderRight}>
            <Box
              className={
                screenType(width, 'sm')
                  ? styles.PowerUsageHeaderTabsMobile
                  : styles.PowerUsageHeaderTabs
              }
            >
              {list.map((item, index) => (
                <button
                  key={item.title}
                  type="button"
                  onClick={() => { setPowerUsage(index) }}
                  className={index === powerUsage ? styles.PowerUsageHeaderTabActive : ''}
                >
                  {item.title}
                </button>
              ))}
            </Box>

            <Box className={styles.PowerUsageHeaderTabDate}>{/* date */}</Box>
          </Box>
        </Box>
        <Box className={styles.PowerUsageHeaderTableBody}>
          {list.map((item, index) => (
            <div
              key={item.title}
              className={index === powerUsage ? styles.PowerUsageHeaderTableBodyActive : ''}
            >
              {typeof window !== 'undefined' &&
                (((account?.myAccount?.serviceAddresses?.[0]) != null)
                  ? (
                    <Chart
                      options={options[index]}
                      series={options[index].series}
                      type="bar"
                      height={312}
                    />
                  )
                  : (
                    <div className={styles.noData}>No Data Available</div>
                  ))}
            </div>
          ))}
          <Typography mb={2} fontSize={12} fontStyle="italic">Data is displayed for the last 12 months. For more information on billing history,&nbsp;<Link to={ROUTES_PATH.BILLING}>click here</Link>.</Typography>
        </Box>

      </Box>
    </Grid>
  )
}
