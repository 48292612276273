import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography } from '@mui/material'
import { MButton, TextInput } from 'components'
import { MESSAGES, ROUTES_PATH, USER_TOKEN } from 'config'
import { useAuth } from 'context/auth'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'

export interface OutagesType {
  address?: string
  city?: string
  lat?: string
  long?: string
  geo?: string
  status?: string
  classData?: string
  pubDate?: string
  error?: string
}

const schema = yup
  .object({
    fullName: yup.string().required('Full name is a required field'),
    outageAddress: yup.string().required('Outage address is a required field'),
    comment: yup.string().required('Comment is a required field')
  })
  .required()

export interface OutagesForm {
  fullName: string
  outageAddress: string
  phoneNumber: string
  emailAddress: string
  comment: string
}

export const Outages: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { account, getAccount, currentAccount, isAdmin, userEmail } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm<OutagesForm>({
    mode: 'all',
    defaultValues: {
      fullName: '',
      outageAddress: '',
      phoneNumber: '',
      emailAddress: '',
      comment: ''
    },
    resolver: yupResolver(schema)
  })
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (account != null) {
      setValue('fullName', account.myAccount.personName)
      setValue('outageAddress', account.myAccount.serviceAddresses?.[0].serviceAddress ?? '')
      setValue('phoneNumber', account?.myAccount?.mobileNumber ?? account?.myAccount?.alternateNumber)
      setValue('emailAddress', account.myAccount.email)
    }
  }, [account])

  const onSubmit = async (data: OutagesForm): Promise<void> => {
    try {
      await APIs.reportOutage(data)
      enqueueSnackbar(MESSAGES.REPORT_OUTAGE_SUCCESS, {
        variant: 'success', autoHideDuration: 3000
      })
    } catch (error) {
      enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
        variant: 'error', autoHideDuration: 3000
      })
    } finally {
      reset()
    }
  }

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} width={location.pathname === ROUTES_PATH.OUTAGES ? '100%' : 'auto'} p={isMobile ? 3 : 5} mt={3} mb={isMobile ? 10 : 20} bgcolor="#ffffff" gap={isMobile ? 3 : 5}>
      <Box component="form" width="100%">
        <Typography mb={2} fontSize={isMobile ? 18 : 24} fontWeight={600} color="#010B0E">Report Outage Form</Typography>
        <Typography mb={3} fontSize={isMobile ? 14 : 16} color="#5D6365">Please provide details about the outage you are experiencing. Your report will help us investigate and resolve the issue promptly.</Typography>
        <TextInput fieldLabel='Full name *' fieldName='fullName' fieldType='text' placeholder='Enter your full name' control={control} errors={errors} />
        <TextInput fieldLabel='Outage address *' fieldName='outageAddress' fieldType='text' placeholder='Enter your outage address' control={control} errors={errors} />
        <TextInput fieldLabel='Phone number' fieldName='phoneNumber' fieldType='text' placeholder='Enter your phone number' control={control} errors={errors} />
        <TextInput fieldLabel='Email address' fieldName='emailAddress' fieldType='email' placeholder='Enter your email address' control={control} errors={errors} />
        <TextInput fieldLabel='Comment *' fieldName='comment' fieldType='text' control={control} errors={errors} multiline={true} placeholder='Enter your comment' />

        <Box display="flex" mt={5} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <MButton fullWidth variant='outlined' texttransform='none' rounded='true' onClick={() => { reset() }}>
            Clear
          </MButton>
          <MButton fullWidth variant='contained' type='submit' texttransform='none' rounded='true' onClick={handleSubmit(onSubmit)}>
            Submit
          </MButton>
        </Box>
      </Box>
    </Box>
  )
}
