import Box from '@mui/material/Box'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { GenericDialog, MButton } from 'components'
import { Checkbox, Typography } from '@mui/material'
import { PAPERLESS_LINK } from 'config'
import { useState } from 'react'

interface IPaperlessBillingDialog {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
  isLoading: boolean
  paperlessBilling: boolean
}

export const PaperlessBillingDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  paperlessBilling
}: IPaperlessBillingDialog): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const [consent, setConsent] = useState<boolean>(false)

  const handleTermsLink = (): void => {
    window.open(PAPERLESS_LINK, '_blank', 'noopener,noreferrer')
  }

  return (
    <Box>
      <GenericDialog
        open={open}
        onClose={onClose}
        isLoading={isLoading}
        title={`${
          paperlessBilling ? 'Enroll' : 'Unenroll'
        } from Paperless Billing`}
      >
        <Box>
          <Typography
            color='#5D6365'
            fontSize={isMobile ? 14 : 16}
            textAlign={isMobile ? 'justify' : 'center'}
          >
            {`You are about to  ${
              paperlessBilling ? 'enroll' : 'unenroll'
            } for paperless billing services.`}
          </Typography>

          <Typography
            color='#5D6365'
            fontSize={isMobile ? 14 : 16}
            textAlign={isMobile ? 'justify' : 'center'}
            mb={3}
          >
            Do you want to proceed?
          </Typography>

          <Box display='flex' flexDirection='column' gap={1}>
            {paperlessBilling && (
              <Box display="flex" gap={1} mb={2}>
                <Checkbox
                  style={{ width: '20px', padding: 0 }}
                  checked={consent}
                  onChange={() => {
                    setConsent(!consent)
                  }}
                  name="consent"
                />

                <Box
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  gap={1}
                >
                  <Typography
                    color="#5D6365"
                    fontSize={isMobile ? 12 : 14}
                    textAlign={isMobile ? 'justify' : 'center'}
                  >
                    By enabling Paperless, I agree to the
                  </Typography>

                  <Box onClick={handleTermsLink}>
                    <Typography
                      color="#1513C3"
                      fontSize={isMobile ? 12 : 14}
                      sx={{ textDecoration: 'underline' }}
                      display="inline"
                    >
                      Invoice Cloud Terms and Conditions.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <MButton
              variant='contained'
              size='large'
              rounded='true'
              fullWidth
              onClick={onConfirm}
              texttransform='none'
              loading={isLoading}
              disabled={isLoading || (!consent && paperlessBilling)}
            >
              {`Yes, ${
                paperlessBilling ? 'enroll' : 'unenroll'
              } to Paperless Billing`}
            </MButton>
            <MButton
              variant='outlined'
              type='button'
              size='large'
              rounded='true'
              fullWidth
              onClick={onClose}
              texttransform='none'
              disabled={isLoading}
            >
              Discard Changes, back to Account Preferences
            </MButton>
          </Box>
        </Box>
      </GenericDialog>
    </Box>
  )
}
