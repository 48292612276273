/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Grid, MenuItem, Select } from '@mui/material'
import CalendarOne from 'assets/icons/Calendar-1.svg'
import Calendar from 'assets/icons/Calendar.svg'
import Clock from 'assets/icons/Clock.svg'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import type React from 'react'
import { useState } from 'react'
import { screenType, useWindowSize } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { UsageView } from './components'
import TabStyles from './components/tabs.module.scss'

const MENU_ITEMS = [
  { title: 'Calendar', label: 'Monthly View', icon: Calendar },
  { title: 'CalendarOne', label: 'Daily View', icon: CalendarOne },
  { title: 'Clock', label: 'Hourly View', icon: Clock }
]

export const Usage: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const { width } = useWindowSize()
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMobileTabsClick = (e: any) => {
    setTab(e.target.value)
  }

  const style: any = isLoading
    ? { pointerEvents: 'none', opacity: 0.5 }
    : { pointerEvents: 'auto', opacity: 1 }

  return (
    <>
      {isMobile && <QuickLinks />}
      <Grid className={TabStyles.Tabs}>
        {screenType(width, 'sm')
          ? (
            <Box className={TabStyles.TabHeaderMobile}>
              <Select
                value={tab}
                label="View"
                classes={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  root: TabStyles.selectMui,
                  icon: TabStyles.MuiIcon
                }}
                onChange={handleMobileTabsClick}
                style={{ width: '100%', alignSelf: 'center' }}
              >
                <MenuItem value={0}>
                  <object data={Calendar} style={{ paddingRight: 10 }} title="Title" />
                  Monthly View
                </MenuItem>
                <MenuItem value={1}>
                  <object data={CalendarOne} style={{ paddingRight: 10 }} title="Title" />
                  Daily View
                </MenuItem>
                <MenuItem value={2}>
                  <object data={Clock} style={{ paddingRight: 10 }} title="Title" />
                  Hourly View
                </MenuItem>
              </Select>
            </Box>
          )
          : (
            <Box className={TabStyles.TabHeader}>
              <ul>
                {MENU_ITEMS.map((menu, index) => (
                  <li key={menu.title}>
                    <a onClick={() => { setTab(index) }} style={style} className={`${tab === index ? TabStyles.TabActive : ''}`}>
                      <object data={menu.icon} title="Calendar" />
                      {menu.label}
                    </a>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        <Grid className={TabStyles.TabBodyInner}>
          <UsageView
            isLoading={isLoading}
            handleLoading={setIsLoading}
            tabIndex={tab}
          />
        </Grid>
      </Grid>
    </>
  )
}
