import { Box, Link } from '@mui/material'
import { Text } from 'components'
import { EFFICIENCY_ARTICLES } from 'config'
import type React from 'react'

export const Savings: React.FC = () => {
  return (
    <Box bgcolor='#FFFFFF' borderRadius='4px' height="96%">
      <Box p={3} display="flex" flexDirection="column" gap={3}>
        <Text color='#000000' fontSize='18px' fontWeight='600'>
          Efficiency
        </Text>
        {EFFICIENCY_ARTICLES.map((item, index) =>
          <Box key={index} bgcolor='#F9F9FD' display='flex' alignItems='center' justifyContent='space-between' p={2}>
            <Text fontWeight='700' fontSize='16px' color='#000000'>
              {item.title}
            </Text>
            <Link sx={{ color: '#1513C3', textDecoration: 'none', cursor: 'pointer' }} onClick={() => window.open(item.link, '_blank')}>Read More</Link>
          </Box>
        )}
      </Box>
    </Box >

  )
}
