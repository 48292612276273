import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography
} from '@mui/material'
import { ErrorText, MButton, Text } from 'components'
import { EMAIL_ADDRESS_UNRECOGNIZED_ERROR, MESSAGES, ROUTES_PATH } from 'config'
import { EmailNotFoundDialog } from 'containers/Auth/ForgotPassword/component/EmailNotFoundDialog'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useSnackbar } from 'notistack'
import type React from 'react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import * as yup from 'yup'

const schema1 = yup
  .object({
    lastFourId: yup.string().max(4, 'Maximum of four characters only').required('This field is required'),
    accountId: yup.string().max(20).required('This field is required'),
    email: yup.string().email().required('This field is required')
  })
  .required()

const schema2 = yup
  .object({
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/,
        'Password must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character'
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })
  .required()

interface IRegisterForm {
  lastFourId: string
  accountId: string
  email: string
}

interface IRegisterPasswordForm {
  password: string
  passwordConfirm: string
}

export function BackToLogin(): JSX.Element {
  return (
    <Box mt="40px">
      <Link href={ROUTES_PATH.SIGNIN} underline="none">
        <Text color="#1513C3" fontSize="16px" align="center">
          Back to Login
        </Text>
      </Link>
    </Box>
  )
}

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [step, setStep] = useState<number>(0)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false)
  const [formData, setFormData] = useState<any>({})
  const [showEmailUnrecognizedModal, setShowEmailUnrecognizedModal] = useState<boolean>(false)
  const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IRegisterForm>({
    mode: 'all',
    defaultValues: {
      accountId: '',
      lastFourId: '',
      email: ''
    },
    resolver: yupResolver(schema1)
  })

  const handleNextStep = async (data: IRegisterForm): Promise<void> => {
    setIsRegisterLoading(true)
    try {
      const registerData = await APIs.verifyAccount({
        email: data.email,
        lastFourId: data.lastFourId,
        accountId: data.accountId
      })

      if (registerData.status === 1) {
        enqueueSnackbar(MESSAGES.ACCOUNT_VERIFIED, {
          variant: 'info',
          autoHideDuration: 5000
        })
        setStep(1)
        setFormData(data)
      } else {
        if (registerData?.message?.message === EMAIL_ADDRESS_UNRECOGNIZED_ERROR) {
          setShowEmailUnrecognizedModal(true)
        } else {
          enqueueSnackbar(
            registerData?.message?.message || MESSAGES.WRONG_ERROR,
            {
              variant: 'error'
            }
          )
        }
      }
    } catch (e) {
      console.error('SignUp.Form.Register', e)
      enqueueSnackbar(MESSAGES.WRONG_ERROR, {
        variant: 'error'
      })
    }
    setIsRegisterLoading(false)
  }

  const {
    handleSubmit: handlePasswordSubmit,
    control: passwordControl,
    formState: { errors: pwdErrors, isValid: pwdIsValid }
  } = useForm<IRegisterPasswordForm>({
    mode: 'all',
    defaultValues: {
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(schema2)
  })
  const [isPwdLoading, setIsPwdLoading] = useState<boolean>(false)

  const onSubmit = async (data: IRegisterPasswordForm): Promise<void> => {
    setIsPwdLoading(true)
    try {
      const registerData = await APIs.registerUser({
        email: formData.email,
        password: data.password,
        zipCode: formData.zipCode,
        accountId: formData.accountId
      })

      if (registerData.status === 1) {
        enqueueSnackbar(MESSAGES.VERIFICATION_CODE_CHECK, {
          variant: 'info',
          autoHideDuration: 5000
        })
        navigate(ROUTES_PATH.SIGNUP_CONFIRM, { state: formData.email })
      } else {
        enqueueSnackbar(
          registerData?.message?.message || MESSAGES.WRONG_ERROR,
          {
            variant: 'error'
          }
        )
      }
    } catch (e: any) {
      const { data } = e.response
      console.error('SignUp.Form.Register', e)
      enqueueSnackbar(data?.message?.message || MESSAGES.WRONG_ERROR, {
        variant: 'error'
      })
    }
    setIsPwdLoading(false)
  }

  function StepBackComponent(): JSX.Element {
    return (
      <Link mt={1} onClick={() => { setStep(step - 1) }} underline="none">
        <Text color="#1513C3" fontSize="16px" align="center" ishover="true">
          Step back
        </Text>
      </Link>
    )
  }

  return (
    <Box>
      <Typography color="#010B0E" fontSize={24} fontWeight={600} mb={3}>
        Register
      </Typography>
      {
        step === 0 && (
          <form key={1} method="post" onSubmit={handleSubmit(handleNextStep)}>
            <Box mb={2}>
              <FormLabel>Account Number</FormLabel>
              <Controller
                name="accountId"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    name={name}
                    placeholder="Enter your account number"
                    onChange={onChange}
                    inputProps={{ maxLength: 10 }}
                    value={value}
                    type="text"
                    fullWidth
                  />
                )}
              />
              {errors.accountId && (
                <ErrorText>{errors.accountId.message}</ErrorText>
              )}
            </Box>

            <Box mb={2}>
              <FormLabel>Email Address</FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    name={name}
                    placeholder="Enter your email address"
                    onChange={onChange}
                    value={value}
                    type="text"
                    fullWidth
                  />
                )}
              />
              {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            </Box>

            <Box mb={2}>
              <FormLabel>Last four (4) digits of SSN, EIN, or Driver&apos;s License</FormLabel>
              <Controller
                name="lastFourId"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    name={name}
                    placeholder="0000"
                    onChange={onChange}
                    value={value}
                    inputProps={{ maxLength: 4 }}
                    type="text"
                    fullWidth
                  />
                )}
              />
              {(errors.lastFourId != null) && <ErrorText>{errors.lastFourId.message}</ErrorText>}
            </Box>
            <Box mt={3} mb={1} display="flex" justifyContent="center">
              <MButton
                variant="contained"
                type="submit"
                size="large"
                rounded="true"
                disabled={!isValid || isRegisterLoading}
                loading={isRegisterLoading}
                sx={{ width: '160px' }}
              >
                NEXT
              </MButton>
            </Box>

            <BackToLogin />
          </form>
        )
      }

      {/* Password confirmation Form */}
      {
        step === 1 && (
          <form key={2} method="post" onSubmit={handlePasswordSubmit(onSubmit)}>
            <Box mb={2}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Controller
                name="password"
                control={passwordControl}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    placeholder="Enter a new password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    name={name}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowPassword(!showPassword) }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {pwdErrors.password && (
                <ErrorText>{pwdErrors.password.message}</ErrorText>
              )}
            </Box>

            <Box mb={2}>
              <FormLabel>Confirm password</FormLabel>
              <Controller
                name="passwordConfirm"
                control={passwordControl}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    placeholder="Confirm your password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    name={name}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowConfirmPassword(!showConfirmPassword) }
                          }
                          edge="end"
                        >
                          {showConfirmPassword
                            ? (<VisibilityOff />)
                            : (<Visibility />)}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {(pwdErrors.passwordConfirm != null) && (
                <ErrorText>{pwdErrors.passwordConfirm.message}</ErrorText>
              )}
            </Box>

            {/* <span className={styles.error}>
            {hasAuthError && <Box>{formState.errors.auth}</Box>}
          </span> */}

            <Box my={3} textAlign="center">
              <MButton
                variant="contained"
                type="submit"
                size="large"
                rounded="true"
                loading={isPwdLoading}
                disabled={!pwdIsValid || isPwdLoading}
              >
                Confirm password
              </MButton>
            </Box>

            <StepBackComponent />

            <BackToLogin />
          </form>
        )
      }
      <EmailNotFoundDialog open={showEmailUnrecognizedModal} close={() => { setShowEmailUnrecognizedModal(false) }} />
    </Box >
  )
}
