import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import TungstenIcon from '@mui/icons-material/Tungsten'
import { Box } from '@mui/material'
import { Text } from 'components'
import { type IDashboardStats } from 'containers/Dashboard/Dashboard'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'


interface IProps {
  dashboardStats: IDashboardStats
}

export const PowerCompare = ({ dashboardStats }: IProps): JSX.Element => {
  const [compare, setCompare] = useState<any[]>()
  const startDate = dayjs().subtract(1, 'year').format('MMMM YYYY')
  const endDate = dayjs().format('MMMM YYYY')
  const isMobile = useMobileBreakpoints()
  useEffect(() => {
    setCompare([
      {
        id: 1,
        title: 'Monthly Average',
        image: ElectricBoltIcon,
        value: `${dashboardStats.monthlyAverage} kWh`
      },
      {
        id: 2,
        title: 'Total Usage',
        image: TungstenIcon,
        value: `${dashboardStats.totalUsage} kWh`
      }
    ])
  }, [dashboardStats])

  return (
    <Box height="100%" bgcolor="#FFFFFF" borderRadius="4px">
      <Box p={3}>
        <Box mb={3}>
          <Text color="#000000" fontSize="18px" fontWeight="600">
            Previous 12 months
          </Text>
          <Text color="#343C3E" fontSize="12px">
            {`${startDate} - ${endDate}`}
          </Text>
        </Box>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
          {compare?.map((item: any) => (
            <Box
              key={item.id}
              flex={1}
              display="flex"
              flexDirection={isMobile ? 'row' : 'column'}
              p={2}
              gap={1}
              border="1px solid #E5E5F6"
              borderRadius="4px"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bgcolor="#F9F9FD"
                p={2}
              >
                <item.image sx={{ color: '#3532F4', fontSize: 40 }} />
              </Box>
              <Box>
                <Text
                  align={isMobile ? 'left' : 'center'}
                  color="#000000"
                  fontSize="18px"
                  fontWeight="600"
                >
                  {item.value}
                </Text>
                <Text align={isMobile ? 'left' : 'center'} color="#5D6365" fontSize="14px">
                  {item.title}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
