import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import type React from 'react'

interface PayBillDialogProps {
    isOpen: boolean
    handleDialog: () => void
}

export const PayBillDialog: React.FC<PayBillDialogProps> = ({ isOpen, handleDialog }) => {
    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                Notice
            </DialogTitle>
            <DialogContent>
                <DialogContentText mb={2} textAlign="justify">
                    Scheduled payments may not prevent disconnection of service or assessment of late fees.  If your account is past due, please contact our 24-Hour Customer Service Team at (844) 341-6469.
                </DialogContentText>
                <DialogContentText textAlign="justify">
                    If you have made a payment, it may take a few minutes for the balance to reflect the payment. Please refresh your page or log back in to view the changes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialog}>I Understand</Button>
            </DialogActions>
        </Dialog>
    )
}
