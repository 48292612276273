/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent
} from '@mui/material'
import { ProgressLoaders } from 'components'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useMemo, useState, type Key, type SetStateAction } from 'react'
import Chart from 'react-apexcharts'
import { defaultOptions, formatValue, screenType, useLocalStorage, useWindowSize } from 'utils'
import { type BillingData, type BillingSegmentData, type SQS } from 'utils/hooks/types'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import styles from './Usage.module.scss'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const DAY = 'day'
const MONTH = 'month'
const YEAR = 'year'

const KWH = 'KWH'
const GEN = 'GEN'
const CONSUMED = 'CONSUMED'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100
    }
  }
}
export const UsageView = ({ tabIndex, isLoading, handleLoading }: any) => {
  const { getUsage, premiseId, isAdmin, userEmail, currentAccount, account, getBilling, getAccount } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [graphData, setGraphData] = useState<any>({
    kwh: { dates: [], values: [] },
    kw: { dates: [], values: [] },
    billing: { dates: [], values: [] }
  })
  const currentYear = dayjs().year()
  const currentMonth = dayjs().month() + 1
  const currentDay = dayjs().date()
  const [selectedYear, setSelectedYear] = useState<string>(
    currentYear.toString()
  )
  const [selectedMonth, setSelectedMonth] = useState<string>(
    currentMonth.toString()
  )
  const [selectedDay, setSelectedDay] = useState<string>(
    currentDay.toString()
  )
  const [layed, setLayed] = useState<any>([])
  const isMobile = useMobileBreakpoints()
  const [currentLayed, setCurrentLayed] = useState(0)
  const { width } = useWindowSize()
  const [table, setTable] = useState<any>({ year: currentYear, month: dayjs().month() + 1, day: dayjs().date() })
  const [graphOptions, setGraphOptions] = useState<any>([
    { ...defaultOptions, series: [] },
    { ...defaultOptions, series: [] },
    { ...defaultOptions, series: [] }
  ])
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const meter = account?.myAccount?.meterNumber ?? null
  const getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate()
  const yearOptions = Array.from({ length: 2 }, (_, index) =>
    (currentYear - index).toString()
  )
  const handleYearChange = (event: SelectChangeEvent): void => {
    if (event.target.name === MONTH) {
      setSelectedMonth(event.target.value)
    } else if (event.target.name === DAY) {
      setSelectedDay(event.target.value)
    } else {
      setSelectedYear(event.target.value)
    }
  }


  useEffect(() => {
    const fetchYearlyUsage = async (): Promise<void> => {
      handleLoading(true)
      let startDate, endDate, frequency
      if (tabIndex === 0) {
        startDate = `${selectedYear}-01-01`
        endDate = dayjs(`${selectedYear}-01-01`).add(1, 'year').format('YYYY-MM-DD')
        frequency = 'M'
      } else if (tabIndex === 1) {
        startDate = dayjs(`${selectedYear}-${selectedMonth}-01`).format('YYYY-MM-DD')
        endDate = dayjs(`${selectedYear}-${selectedMonth}-01`).endOf('month').format('YYYY-MM-DD')
        frequency = 'D'
      } else {
        startDate = (`${selectedYear}-${selectedMonth}-${selectedDay}:00:00:00`)
        endDate = (`${selectedYear}-${selectedMonth}-${selectedDay}:23:59:59`)
        frequency = 'H'
      }
      try {
        let billingValue = null
        const usageValue = await getUsage({
          AccessToken: authToken,
          premiseId: currentAccount?.premiseId ?? premiseId,
          accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
          frequency,
          startDate,
          endDate,
          service: '',
          admin: isAdmin,
          email: userEmail
        })
        if (tabIndex === 0) {
          billingValue = await getBilling({
            AccessToken: authToken,
            accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
            startDate,
            endDate,
            admin: isAdmin,
            email: userEmail
          }, true)
        }
        if (usageValue === null) {
          setGraphOptions([
            { ...defaultOptions, series: [] },
            { ...defaultOptions, series: [] },
            { ...defaultOptions, series: [] }
          ])
        } else if (usageValue !== null) {
          await fetchUsageView(usageValue.usage, billingValue)
        }
      } catch (error) {
        console.error('Error fetching yearly usage:', error)
      } finally {
        handleLoading(false)
      }
    }

    void fetchYearlyUsage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiseId, currentAccount, authToken, selectedYear, selectedMonth, selectedDay, isAdmin, userEmail, tabIndex])

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])


  useEffect(() => {
    setCurrentLayed(0)
    if (tabIndex === 0) {
      setLayed(['kWh', '$$$', 'KW'])
    } else setLayed(['kWh'])
  }, [graphOptions, tabIndex])

  const paddingStyle = screenType(width, 'sm')
    ? {
      paddingLeft: '30px',
      paddingRight: '30px'
    }
    : {}

  const marginBottomStyle = screenType(width, 'sm')
    ? {
      width: '100%',
      padding: '0px 0px !important',
      marginBottom: '10px'
    }
    : {}

  const previewTitle = useMemo(() => {
    let title = 'Select the year to preview:'

    if (tabIndex === 1) {
      title = 'Select a month to preview:'
    } else if (tabIndex === 2) {
      title = 'Select a day to preview:'
    }

    return title
  }, [tabIndex])

  const getKWH = () => {
    if (graphData?.kwh?.values?.length === 0) {
      return []
    }
    if (account?.myAccount?.isNEM === true) {
      return [
        {
          name: 'Net',
          data: graphData?.kwh?.values?.net ?? []
        },
        {
          name: 'Consumed',
          data: graphData?.kwh?.values?.consumed ?? []
        },
        {
          name: 'Generated',
          data: graphData?.kwh?.values?.generated ?? []
        }
      ]
    }

    return [
      {
        name: 'Usage',
        data: graphData?.kwh?.values?.consumed ?? []
      }
    ]
  }

  const fetchUsageView = async (usageV: any, billingV: any) => {
    const billingDates: string[] = []
    const billingValues: string[] = []
    const kwDates: any[] = []
    const kwValues: any[] = []
    const kwhDates: any[] = []
    const kwhValues: any = {
      consumed: [],
      generated: [],
      net: []
    }
    const kwhMap: Record<string, { consumed?: number, generated?: number }> = {}

    if (usageV !== null) {
      if (billingV !== null) {
        const sortedBillData = _.sortBy(billingV.billing, 'readDate')
        sortedBillData.forEach(bill => {
          billingDates.push(formatGraphDate(bill.readDate))
          billingValues.push(bill.billAmount)
        })
      }
      usageV.usageHistory?.forEach((history: any) => {
        if (history.uom.toUpperCase() === KWH && usageV?.billData === undefined) {
          history?.usageData?.forEach((item: any) => {
            const period = item.period // Extracting date from period
            const value = parseFloat(item.value)
            if (!kwhMap[period]) {
              kwhMap[period] = {}
            }

            if (history.sqi === CONSUMED) {
              kwhMap[period].consumed = value
            } else if (history.sqi === GEN) {
              kwhMap[period].generated = value
            }
          })
        } else if (usageV?.frequency === 'M' && history.uom.toUpperCase() === 'KW') {
          const date = new Date()
          history?.usageData?.filter((item: any) => new Date(item.period) <= date)
            .forEach((item: any) => {
              kwDates.push(formatGraphDate(item.period)
              )
              kwValues.push(Math.round(item.value))
            })
        }
      })

      if (usageV?.frequency === 'M' && usageV?.billData !== undefined) {
        const sortedBillData = _.sortBy(usageV?.billData, 'readDate')
        sortedBillData.forEach((billingData: BillingData) => {
          kwhDates.push(formatGraphDate(billingData.readDate))

          let totalConsumed = 0
          let totalGenerated = 0
          let totalNet = 0

          billingData?.billSegmentData?.forEach((segment: BillingSegmentData) => {
            segment?.sq?.sqs?.forEach((sqs: SQS) => {
              if (sqs.uom?.toUpperCase() === KWH) {
                switch (sqs.sqi) {
                  case 'CONSUMED':
                    totalConsumed += parseFloat(sqs?.billSq ?? '0')
                    break
                  case 'GEN':
                    totalGenerated += parseFloat(sqs?.billSq ?? '0')
                    break
                  case 'NET':
                    totalNet += parseFloat(sqs?.billSq ?? '0')
                    break
                  default:
                    break
                }
              }
            })
          })

          kwhValues.consumed.push(totalConsumed)
          kwhValues.generated.push(totalGenerated)
          kwhValues.net.push(totalNet)
        })
      }
      // Create an array of sorted periods
      const sortedPeriods = Object.keys(kwhMap).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

      sortedPeriods.forEach(period => {
        const { consumed = 0, generated = 0 } = kwhMap[period]
        kwhDates.push(formatGraphDate(period))
        kwhValues.consumed.push(consumed)
        kwhValues.generated.push(generated)
        kwhValues.net.push(parseFloat((consumed - generated).toFixed(3)))
      })

      setGraphData({
        billing: {
          dates: billingDates,
          values: billingValues
        },
        kwh: {
          dates: kwhDates,
          values: kwhValues
        },
        kw: {
          dates: kwDates,
          values: kwValues
        }
      })
    }
  }

  const formatGraphDate = (date: string) => {
    if (tabIndex === 0) {
      return dayjs(date).format('MMM YY')
    } else if (tabIndex === 1) {
      return dayjs(date).format('MMM D')
    } else if (tabIndex === 2) {
      // manually date to time stamp, dayjs() won't accept the format
      const year = parseInt(date.substring(0, 4), 10)
      const month = parseInt(date.substring(5, 7), 10) - 1
      const day = parseInt(date.substring(8, 10), 10)
      const hour = parseInt(date.substring(11, 13), 10)

      return dayjs(new Date(year, month, day, hour)).format('h A')
    }

    return date
  }

  useEffect(() => {
    setGraphOptions([
      {
        ...defaultOptions,
        chart: {
          toolbar: {
            export: {
              csv: {
                filename: tabIndex === 0 ? `Monthly View ${selectedYear}` : tabIndex === 1 ? `Daily View ${selectedYear}-${selectedMonth}` : `Hourly View ${selectedYear}-${selectedMonth}-${selectedDay}`,
                categoryFormatter(x: any) {
                  if (tabIndex === 2) {
                    return `${selectedYear}-${selectedMonth}-${selectedDay} ${x}`
                  } else return x
                },
                valueFormatter(y: any) {
                  if (tabIndex === 0) {
                    return formatValue(y, false, false, false, false)
                  } return y
                }
              }
            }
          },
          animations: {
            enabled: true
          }
        },
        series: getKWH(),
        xaxis: {
          categories: graphData?.kwh?.dates,
          labels: {
            show: true,
            formatter(val: string) {
              if (graphData?.kwh?.dates?.length === 0) return ''
              return val
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            show: true,
            formatter(val: any) {
              if (tabIndex === 0) {
                return formatValue(val, false, false, false, false)
              } return val
            }

          }
        },
        tooltip: {
          y: {
            formatter(val: any) {
              if (tabIndex === 0) {
                return formatValue(val, false, false, false, false)
              } return val
            }
          }
        },
        option: {
          chart: {
            id: 'option-1'
          }
        }
      },
      {
        ...defaultOptions,
        chart: {
          toolbar: {
            export: {
              csv: {
                valueFormatter(y: any) {
                  return formatValue(y, true, true, false, false)
                }
              }
            }
          },
          animations: {
            enabled: true
          }
        },
        series: [
          {
            name: 'Monthly Bill',
            data: graphData?.billing?.values
          }
        ],
        xaxis: {
          categories: graphData?.billing?.dates,
          labels: {
            show: true,
            formatter(val: any) {
              if (graphData?.billing?.dates?.length === 0) return ''
              return val
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val: any) {
              return formatValue(val, true, true)
            }
          }
        },
        tooltip: {
          y: {
            formatter(val: any) {
              return formatValue(val, true, true)
            }
          }
        },
        option: {
          chart: {
            id: 'option-2'
          }
        }
      },
      {
        ...defaultOptions,
        chart: {
          toolbar: {
            export: {
              csv: {
                valueFormatter(y: any) {
                  return formatValue(y, false, false, false, false)
                }
              }
            }
          },
          animations: {
            enabled: true
          }
        },
        series: [
          {
            name: 'Usage KW',
            data: graphData?.kw?.values
          }
        ],
        xaxis: {
          categories: graphData?.kw?.dates,
          labels: {
            show: true,
            formatter(val: any) {
              if (graphData?.kw?.dates?.length === 0) return ''
              return val
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val: any) {
              if (graphData?.kw?.dates?.length === 0) return ''
              return val
            }
          }
        },
        tooltip: {
          y: {
            formatter(val: any) {
              return formatValue(val, false, false)
            }
          }
        },
        option: {
          chart: {
            id: 'option-3'
          }
        }
      }
    ])
  }, [graphData, width])

  const renderDateDropdown = () => {
    const Dropdown = (
      <>
        {tabIndex !== 0 && (
          <FormControl disabled={isLoading}>
            <Select
              value={table.month}
              name={MONTH}
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression, @typescript-eslint/no-unused-expressions, no-sequences
              onChange={e => { setTable({ ...table, month: e.target.value }), handleYearChange(e) }}
              MenuProps={MenuProps}
              style={{ width: 100, marginRight: 16 }}
            >
              {months.map((item, index) => (
                <MenuItem value={index + 1} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {tabIndex === 2 && (
          <FormControl disabled={isLoading}>
            <Select
              value={table.day}
              name={DAY}
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-confusing-void-expression, no-sequences
              onChange={e => { setTable({ ...table, day: e.target.value }), handleYearChange(e) }}
              MenuProps={MenuProps}
              style={{ marginRight: 16 }}
            >
              {Array.from(
                new Array(getDaysInMonth(table.year, table.month)),
                (_val, index) => getDaysInMonth(table.year, table.month) - index
              ).map((item, i) => (
                <MenuItem value={i + 1} key={item}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl disabled={isLoading}>
          <Select defaultValue=""
            value={table.year}
            name={YEAR}
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-confusing-void-expression, no-sequences
            onChange={e => { setTable({ ...table, year: e.target.value }), handleYearChange(e) }}
          >
            {yearOptions.map((year, index) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )

    return Dropdown
  }

  return (
    <Grid mt={0} p={isMobile ? 4 : 3} bgcolor="#FFFFFF">
      <Box className={styles.ChartButtons} >
        <Box className={styles.ChartButtonLeft} >
          {meter !== null && <span style={{ fontSize: 19, paddingBottom: 12 }}>Meter Number: {meter}</span>}
          <span style={{ fontSize: 19, paddingBottom: 12 }}>
            Select the units to be displayed on the chart:
          </span>
          {screenType(width, 'sm')
            ? (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {layed.map((item: Key | null | undefined, index: SetStateAction<any>) => (
                  <button
                    key={item}
                    type="button"
                    onClick={() => { setCurrentLayed(index) }}
                    className={`${styles.ChartButton} ${index === currentLayed ? styles.ChartButtonActive : ''}`}
                    style={paddingStyle}
                  >
                    {layed[index]}
                  </button>
                ))}
              </div>
            )
            : (
              layed.map((item: Key | null | undefined, index: SetStateAction<any>) => (
                <button
                  key={item}
                  type="button"
                  onClick={() => { setCurrentLayed(index) }}
                  className={`${styles.ChartButton} ${index === currentLayed ? styles.ChartButtonActive : ''}`}
                >
                  {layed[index]}
                </button>
              ))
            )}
        </Box>
        <Box
          className={styles.ChartButtonLeft}
          style={marginBottomStyle}
        >
          <Box
            className={styles.ChartButtonLeft}
            sx={{ flexDirection: 'column' }}
          >
            <span style={{ fontSize: 19, paddingBottom: 12 }}>{previewTitle}</span>

            {screenType(width, 'sm')
              ? (
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                  {renderDateDropdown()}
                </Box>
              )
              : (
                renderDateDropdown()
              )}
          </Box>
        </Box>
      </Box>
      <Grid sx={{ overflowX: 'auto', scrollbarWidth: 'none' }}>
        {isLoading
          ? (
            <Box className={styles.UsageLoading}>
              <ProgressLoaders height="221px" />
            </Box>
          )
          : (
            <Chart
              options={graphOptions[currentLayed]}
              series={graphOptions[currentLayed].series}
              type="bar"
              height={221}
              style={{ width: '100%' }}
            />
          )}
      </Grid>
      {tabIndex !== 0 && <Typography mt={2} fontSize={16}>Please note, the data displayed may not reflect the total usage for the period.</Typography>}
    </Grid>
  )
}
