import { Box, Grid, styled } from '@mui/material'
import LoginLeft from 'assets/images/login-left.png'
import { AuthLayout, Text } from 'components'
import { LOGIN_ITEMS, ROUTES_PATH, WELCOME_DESCRIPTION, WELCOME_TITLE } from 'config'

import { useAuth } from 'context'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Item } from './component'

export const SignIn: React.FC = () => {
  const { handleLogOut } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    void handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <AuthLayout
      title={WELCOME_TITLE}
      desc={WELCOME_DESCRIPTION}
      isAuthPage={true}
    >
      <AuthWrapper isAuthPage={true}>
        <Grid container>
          <Grid item sm={0} md={6}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url("${LoginLeft}")`,
                height: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthMain>
              <Form />
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
      <Box>
        <Text
          color="#ffffff"
          align="center"
          fontSize="24px"
          margin="50px 0 40px"
        >
          How can we help you?
        </Text>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
          rowSpacing={{ xs: 2, md: 4 }}
        >
          {LOGIN_ITEMS.map((item, index) => {
            return (
              <Item
                key={index}
                Icon={item.icon}
                title={item.title}
                route={item.link}
              />
            )
          })}
        </Grid>
      </Box>
    </AuthLayout >
  )
}

export const AuthWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAuthPage'
})<{ isAuthPage?: boolean }>(({ isAuthPage }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: (isAuthPage ?? false) ? '40px 0' : '0'
}))

export const AuthMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRounded'
})<{ isRounded?: boolean }>(({ isRounded }) => ({
  backgroundColor: '#fff',
  padding: '40px',
  borderRadius: (isRounded ?? false) ? '4px' : '0',
  h4: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '32px',
    marginTop: 0
  }
}))
