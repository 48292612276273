import { Box, Typography } from '@mui/material'
import { Wrapper } from './BillingHistory'
import PaymentLocationMap from './PaymentLocationMap'

export const PaymentLocation: React.FC = () => {
  return (
    <Wrapper display="flex" flexDirection="column" height="52vh">
      <Box mb={2}>
        <Typography color="#010B0E" fontSize={18} fontWeight={600}>Need to pay in person?</Typography>
        <Typography color="#5D6365" fontSize={14}>Please visit our payment location.</Typography>
      </Box>
      <PaymentLocationMap />
    </Wrapper>
  )
}
