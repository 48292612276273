import ElectricMeterIcon from '@mui/icons-material/ElectricMeter'
import PowerOffIcon from '@mui/icons-material/PowerOff'
import { Box, Link, Typography } from '@mui/material'
import { ROUTES_PATH, SERVICES, ServicesFooterButtonData } from 'config'
import { useAuth } from 'context'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ServiceFooterButton } from './Services'
import ServiceForm from './component/ServiceForm'
import Accordion from 'components/ui/Accordion'
import StopServiceForm from './component/StopServiceForm'

interface ShowFormType {
  startService: boolean
  stopService: boolean
  meterReadVerification: boolean
  safety: boolean
}
export const AuthorizedServices: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const [showForm, setShowForm] = useState({
    startService: false,
    stopService: false,
    meterReadVerification: false,
    safety: false
  })
  const [showServiceButton, setShowServiceButton] = useState(true)
  const { account } = useAuth()

  const handleClick = (formKey: keyof ShowFormType): void => {
    setShowForm((prevShowForm) => ({
      ...prevShowForm,
      [formKey]: !prevShowForm[formKey]
    }))
    setShowServiceButton(!showServiceButton)
  }

  return (
    <>
      <Box p={isMobile ? 3 : 5} mt={3} mb={10} bgcolor="#ffffff">
        {showServiceButton && <Box>
          <Typography fontSize={isMobile ? 18 : 20} fontWeight={600} color="#010B0E">
            What would you like to do?
          </Typography>
          <Typography fontSize={14}>
            Manage your services with the following options:
          </Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2} my={2}>
            <ServiceButton title='Stop Service' desc='Terminate existing electric service.' icon={PowerOffIcon} onClick={() => { handleClick('stopService') }} />
            <ServiceButton title='Meter Read Verification' desc='Verify meter readings.' icon={ElectricMeterIcon} onClick={() => { handleClick('meterReadVerification') }} />
          </Box>
        </Box>}
        {showForm.stopService && <StopServiceForm title='Stop Service' desc='Terminate existing electric service.' icon={PowerOffIcon} formType="stopService" handleClick={() => { handleClick('stopService') }} />}
        {showForm.meterReadVerification && <ServiceForm title='Meter Read Verification' desc='Verify meter readings.' icon={ElectricMeterIcon} formType="meterReadVerification" handleClick={() => { handleClick('meterReadVerification') }} />}
        <Typography fontSize={16} color="#5D6365" mb={2}>
          Additional Services:
        </Typography>
        <Box mb={4}>
          {SERVICES.map((item, index) => (
            <Accordion key={index} title={item.title}>
              <Box>
                <Typography fontSize={isMobile ? 12 : 14}>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </Typography>
                {item.list != null && (
                  <Box ml={2}>
                    <ul>
                      {item.list.map((item, index) => (
                        <li key={index}><Typography fontSize={isMobile ? 12 : 14}>{item}</Typography></li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            </Accordion>

          ))}
        </Box>
        {ServicesFooterButtonData.map((item, index) => (
          <ServiceFooterButton key={index} title={item.title} desc={item.desc} link={(account != null) ? ROUTES_PATH.CONTACT : item.link} linkTitle={item.linkTitle} icon={item.icon} />
        ))}
      </Box>
    </>
  )
}

interface ServiceButtonProps {
  icon: any
  title: string
  desc: string
  onClick: () => void
}

export const ServiceButton: React.FC<ServiceButtonProps> = (props: ServiceButtonProps) => {
  const isMobile = useMobileBreakpoints()
  return <>
    <Box component={Link} p={3} border="1px solid #B1BADA" flex={1} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { props.onClick() }}>
      <props.icon sx={{ color: '#84B0DA', fontSize: '50px' }} />
      <Typography fontSize={isMobile ? 16 : 18} color="#010B0E">
        {props.title}
      </Typography>
      <Typography fontSize={14} color="#5D6365">
        {props.desc}
      </Typography>
    </Box>
  </>
}
