/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, styled } from '@mui/material'
import { SvgImage } from 'components/ui'
import { MESSAGES } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { screenType, useWindowSize } from 'utils'

interface ICloudPay {
  account: string
  onClose: () => void
}

export const CloudPay = ({ account, onClose }: ICloudPay): JSX.Element => {
  const { width } = useWindowSize()
  const [visible, setVisible] = useState<boolean>(false)
  const [iframeUrl, setIframeUrl] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  const parseMessages = (messages: string[]) => {
    return messages.map(message => message).join(' ')
  }

  const parseErrorObject = (errorObject: any) => {
    const errorMessages: any = []

    Object.keys(errorObject).forEach(key => {
      const messages = errorObject[key]
      if (Array.isArray(messages)) {
        const parsedMessages = parseMessages(messages)
        errorMessages.push(parsedMessages)
      }
    })

    return errorMessages.join(' ')
  }

  useEffect(() => {
    const cloudPay = async (): Promise<void> => {
      try {
        if (account === null) throw new Error('Account ID not found')
        const cloudData = await APIs.getCloudData({ accountNumber: account })

        if (cloudData.data?.Data !== null && cloudData.data?.Data?.CloudPaymentURL !== null) {
          setIframeUrl(cloudData.data?.Data?.CloudPaymentURL)
          setVisible(true)
        } else if (cloudData.error?.Message !== null) {
          const { ModelState } = cloudData.error
          onClose()
          enqueueSnackbar(parseErrorObject(ModelState) || MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
        }
      } catch (e) {
        console.error(e)
        enqueueSnackbar(MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
      }
    }

    void cloudPay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const handleHide = (): void => {
    setVisible(false)
    onClose()
  }

  return (
    <Box>
      <ICModal visible={visible ? 'true' : 'false'}>
        <ICModalContent ismobile={screenType(width, 'sm') ? 'true' : 'false'}>
          <CloseButton
            sx={{ margin: screenType(width, 'sm') ? '5px' : '0' }}
            onClick={handleHide}
          >
            <SvgImage name="CloseIcon" width={24} height={24} />
          </CloseButton>

          {screenType(width, 'sm')
            ? (
              <iframe
                src={iframeUrl}
                width="100%"
                height="90%"
                title="Cloud Pay"
              />
            )
            : (
              <iframe
                src={iframeUrl}
                width="100%"
                height="550"
                title="Cloud Pay"
              />
            )}
        </ICModalContent>
      </ICModal>
    </Box>
  )
}

export const ICModal = styled(Box)<{ visible: string }>(({ visible }) => ({
  position: 'fixed',
  left: 0,
  zIndex: 999,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: visible === 'true' ? 1 : 0,
  visibility: visible === 'true' ? 'visible' : 'hidden',
  transform: visible === 'true' ? 'schle(1)' : 'scale(1.1)',
  transition: 'visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s'
}))

export const ICModalContent = styled(Box)<{ ismobile: string }>(
  ({ ismobile }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: ismobile === 'true' ? '0rem 0.5rem' : '1rem 1.5rem',
    width: ismobile === 'true' ? '100vw' : '45rem',
    borderRadius: ismobile === 'true' ? '0' : '0.5rem',
    height: ismobile === 'true' ? '100vh' : 'inherite'
  })
)

export const CloseButton = styled(Box)({
  float: 'right',
  width: '1.5rem',
  lineHeight: '1.5rem',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '0.25rem',
  backgroundColor: 'lightgray',
  margin: '5px',

  '&:hover': {
    backgroundColor: 'darkgray'
  }
})
