import { Grid } from '@mui/material'
import { AuthLayout } from 'components'
import { AuthWrapper } from 'containers/Auth'
import { Services as ServicesContainer } from 'containers/Services'

export const Services: React.FC = () => {
  return (
    <AuthLayout>
      <AuthWrapper>
        <Grid container>
          <Grid item>
            <ServicesContainer />
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
