import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import { Avatar, Box, Link, Typography } from '@mui/material'
import { InvoiceCloudFrame, YesNoRadio } from 'components'
import { MESSAGES, PAPERLESSFLG, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { PaperlessBillingDialog } from './PaperlessBillingDialog'

interface IPaperlessBilling {
  setIsPrefUpdated: (arg0: boolean) => void
}

export const PaperlessBilling = ({ setIsPrefUpdated }: IPaperlessBilling): JSX.Element => {
  const { account, isAdmin, userEmail } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')

  const [paperlessBilling, setPaperlessBilling] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isMobile = useMobileBreakpoints()

  useEffect(() => {
    if (account?.myAccount != null) {
      setPaperlessBilling(account.myAccount.paperlessFlg === 'Y')
    }
  }, [account])

  const handleUpdateSettings = async (): Promise<void> => {
    setIsLoading(true)
    const body: any = {
      AccessToken: authToken,
      accountId: account?.myAccount.accountId,
      admin: isAdmin,
      email: userEmail,
      updates: [
        {
          object: PAPERLESSFLG,
          value: paperlessBilling ? 'Y' : 'N'
        }
      ]
    }

    try {
      const response = await APIs.updateUser(body)
      setIsPrefUpdated(true)
      if (response?.status === 1) {
        enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
        enqueueSnackbar(
          paperlessBilling
            ? MESSAGES.ENROLL_PAPERLESS_INFO
            : MESSAGES.UNENROLL_PAPERLESS_INFO,
          {
            variant: 'info',
            autoHideDuration: 3000
          }
        )
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        enqueueSnackbar(
          `${e.response.data.message}. ${MESSAGES.CONTACT_CUSTOMER_ERROR}`,
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        )
      }
      setPaperlessBilling(!paperlessBilling)
    }
    setIsLoading(false)
    setOpen(false)
  }

  const handlePaperlessBillingChange = (event: any): void => {
    setPaperlessBilling(event.target.value === 'Y')
    setOpen(true)
  }

  const handleClose = (): void => {
    setPaperlessBilling(!paperlessBilling)
    setOpen(false)
  }

  return (
    <Box
      flex={1}
      p={3}
      borderRadius='4px'
      justifyContent='space-between'
      alignItems='center'
      border='1px solid #E5E5F6'
    >
      <Box alignItems='center'>
        <Box
          display='flex'
          flexDirection='column'
          borderBottom='1px solid #E5E5F6'
          gap={2}
        >
          <Box
            display='flex'
            alignItems='center'
            flexDirection={isMobile ? 'row' : 'column'}
            gap={2}
          >
            <Avatar
              sx={{
                bgcolor: '#F3FBFD',
                width: isMobile ? 50 : 80,
                height: isMobile ? 50 : 80
              }}
            >
              <InsertPageBreakOutlinedIcon
                fontSize={isMobile ? 'small' : 'large'}
                sx={{ color: '#1513C3' }}
              />
            </Avatar>
            <Typography
              color='#010B0E'
              fontSize={isMobile ? 18 : 24}
              fontWeight={600}
              textAlign='center'
            >
              Paperless Billing
            </Typography>
          </Box>
          <Typography
            color='#5D6365'
            fontSize={isMobile ? 14 : 16}
            textAlign={isMobile ? 'justify' : 'center'}
            mb={3}
          >
            Enroll your current account in paperless billing to start receiving your bills digitally. If you have multiple accounts, please <InvoiceCloudFrame page={2}> <Link sx={{ cursor: 'pointer' }}>click here</Link></InvoiceCloudFrame> to view a list of all accounts to update your paperless billing preference.
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          my={2}
        >
          <Typography
            color='#010B0E'
            fontSize={isMobile ? 14 : 16}
            textAlign={isMobile ? 'justify' : 'center'}
          >
            Do you want to enroll this account in paperless billing?
          </Typography>
          <YesNoRadio
            value={paperlessBilling}
            onChange={handlePaperlessBillingChange}
          />
        </Box>
        <PaperlessBillingDialog
          open={open}
          onClose={handleClose}
          onConfirm={handleUpdateSettings}
          isLoading={isLoading}
          paperlessBilling={paperlessBilling}
        />
      </Box>
    </Box>
  )
}
