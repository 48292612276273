import {
  Chat,
  Email,
  Phone,
  Store
} from '@mui/icons-material'
import { Box, Link } from '@mui/material'
import TwitterIcon from 'assets/icons/Social/TW.svg'
import { Text } from 'components'
import { CONTACT_INFORMATION, CONTACT_SOCIAL_ITEMS } from 'config'
import PaymentLocationMap from 'containers/Billing/components/PaymentLocationMap'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IProps {
  isAuthenticated?: boolean

}

const contactIcons = {
  phone: Phone,
  chat: Chat,
  email: Email,
  location: Store
}

export const FindUs = ({ isAuthenticated = true }: IProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box>
        <Text color="#000000" fontWeight="700" fontSize="18px">
          Contact Information
        </Text>
        <Box my={2} display="flex" flexDirection={(isMobile || isAuthenticated) ? 'column' : 'row'} gap={2}>
          {CONTACT_INFORMATION.map((contact, index) => {
            const ContactIcon =
              contactIcons[contact.icon as keyof typeof contactIcons]
            return (
              <Box
                display="flex"
                key={index}
                flex={1}
                flexWrap="wrap"
                sx={{
                  alignItems: 'center',
                  backgroundColor: '#F9F9FD',
                  padding: '15px 15px 25px 15px',
                  '.contact-link': {
                    color: '#1513C3',
                    textDecoration: 'underline'
                  }
                }}
              >
                <ContactIcon
                  sx={{
                    color: '#3532F4',
                    marginRight: '10px',
                    backgroundColor: '#EFEFFA',
                    padding: '10px',
                    borderRadius: '100px',
                    marginTop: '10px'
                  }}
                />
                <Text color="#010B0E" fontSize="18px" fontWeight="600">
                  {contact.title}
                </Text>
                <span
                  style={{
                    width: '100%',
                    marginLeft: '55px',
                    marginTop: '-5px'
                  }}
                >
                  <Text color="#5D6365" fontSize="16px">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contact.description
                      }}
                    />
                  </Text>
                </span>
              </Box>
            )
          })}
        </Box>
      </Box>
      {isAuthenticated && (
        <Box display="flex" py={3} gap={2} flexDirection={isMobile ? 'column' : 'row'}>
          <Text color="#000000" fontWeight="700" fontSize="18px">
            Follow us at:
          </Text>
          <Box>
            {CONTACT_SOCIAL_ITEMS.slice(0, 3).map((social, index) => {
              return (
                <Link
                  key={index}
                  display='flex'
                  mb={2}
                  alignItems='center'
                  sx={{
                    textDecoration: 'none'
                  }}
                  onClick={() => {
                    window.open(social.link)
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {social.icon === null
                    ? <img
                      src={TwitterIcon}
                      alt="Twitter"
                      style={{
                        width: '18px',
                        height: '18px',
                        marginLeft: '3px'
                      }}
                    />
                    : <social.icon sx={{ color: '#3532F4' }} />}
                  <Text
                    fontSize="14px"
                    block="false"
                    color="#010B0E"
                    margin="0 0 0 10px"
                  >
                    {social.title}
                  </Text>
                </Link>
              )
            })}
          </Box>
        </Box>
      )}
      <PaymentLocationMap />

    </Box>
  )
}
