export const defaultOptions = {
  colors: ['#1513C3', '#84B0DA', '#146e02'],
  chart: {
    type: 'bar',
    height: 217
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%'
    }
  },
  stroke: {
    colors: ['transparent'],
    width: 4
  },
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  noData: {
    text: 'No data available',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: 'grey',
      fontSize: '25px',
      fontFamily: 'sans-serif',
      fontWeight: '900',
      backgroundColor: 'white'
    }
  }
}
