import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import { Box, IconButton, styled } from '@mui/material'
import { MButton, Text } from 'components'
import { ROUTES_PATH } from 'config'
import { useNavigate } from 'react-router-dom'

export const PaperlessBilling: React.FC = () => {
  const navigate = useNavigate()

  const handleGoTo = (url: string): void => {
    navigate(url)
  }

  return (
    <Wrapper height="100%" onClick={() => { handleGoTo(ROUTES_PATH.SETTINGS) }}>
      <Content>
        <IconButton sx={{ background: '#F9F9FD', padding: '12px' }}>
          <InsertPageBreakOutlinedIcon
            sx={{ color: '#B5B7E6', fontSize: '32px' }}
          />
        </IconButton>
        <Text
          color="#5D6365"
          fontWeight="bold"
          fontSize="18px"
          margin="16px 0 4px"
        >
          Go to Settings
        </Text>
        <Text
          color="#010B0E"
          fontWeight="bold"
          fontSize="40px"
          align='center'
          margin="0 0 9px"
        >
          Paperless Billing
        </Text>
        <Text color="#5D6365" fontSize="14px" margin="0 0 18px">
          More convenience less clutter
        </Text>
        <MButton
          variant="contained"
          texttransform="none"
          rounded="true"
          sx={{ width: '200px' }}
          size="large"
        >
          Get started
        </MButton>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 24px 20px;
`
