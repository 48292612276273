/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Container, IconButton, Link, Typography, styled } from '@mui/material'
import Logo from 'assets/icons/MVULogo.svg'
import WatermarkImg from 'assets/images/watermark.png'
import { MButton, SvgImage, Text } from 'components'
import { AUTH_TOPBAR_ITEMS, FOOTER_SOCIAL_LINKS, ROUTES_PATH } from 'config'
import type React from 'react'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IAuth {
  title?: string
  desc?: string
  subTitle?: string
  children: React.ReactNode
  isAuthPage?: boolean
}

export const AuthLayout = ({
  title,
  desc,
  isAuthPage = false,
  children
}: IAuth): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMobileBreakpoints()

  const authTitle = useMemo(() => {
    const pageData = AUTH_TOPBAR_ITEMS.find(
      (item) => item.href === location.pathname
    )

    return pageData?.title
  }, [location.pathname])

  const handleOpenInNewTab = (url: string): void => {
    window.open(url, '_blank')
  }

  const isFullScreen = useMemo(() => {
    if (
      location.pathname === ROUTES_PATH.PAY ||
      location.pathname === ROUTES_PATH.SIGNUP_SUCCESS ||
      location.pathname === ROUTES_PATH.FORGOT_PASSWORD ||
      location.pathname === ROUTES_PATH.CONTACT_US
    ) {
      return true
    }

    return false
  }, [location.pathname])

  return (
    <>
      <Wrapper isFullScreen={isFullScreen} isMobile={isMobile}>
        {isAuthPage
          ? (
            <Box display="flex" flexDirection="column" my={5} gap={3} alignItems="center" justifyContent="center">
              <Link href="/" my={3}><img src={Logo} height={50} alt="Logo" /></Link>
              <Typography textAlign="center" color="#010B0E" fontSize={32}>{title}</Typography>
              <Typography textAlign="center" color="#4d515f" fontSize={14} width={isMobile ? '90%' : '60%'}>{desc}</Typography>
            </Box>
          )
          : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={4}
              px={3}
              sx={{ backgroundColor: isMobile ? '#ffffff' : '#F9F9FD', borderBottom: isMobile ? '1px solid #E5E5F6' : '0px' }}
            >
              <Box display="flex" alignItems="center">
                <Link href="/">
                  <img src={Logo} height={isMobile ? 25 : 40} alt="Logo" />
                </Link>
                {!isMobile && authTitle && (
                  <Text
                    color="#000000"
                    fontSize="32px"
                    fontWeight="600"
                    margin="0 0 0 20px"
                  >
                    {authTitle}
                  </Text>
                )}
              </Box>
              <MButton
                variant="outlined"
                rounded="true"
                texttransform="none"
                onClick={() => {
                  navigate(ROUTES_PATH.SIGNIN)
                }}
              >
                Back to Login
              </MButton>
            </Box>
          )}
        <Container>
          <Content>
            {isMobile && authTitle && (
              <Text
                color="#000000"
                fontSize="24px"
                fontWeight="600"
                padding="20px 0 20px 0"
              >
                {authTitle}
              </Text>
            )}
            <Box width="100%" mb={5}>
              {children}
            </Box>
          </Content>
          <Background src={WatermarkImg} alt="Background" />
          <Footer>
            <Text color="#E3E3E3" fontSize="14px">
              © {new Date().getFullYear()}. All rights reserved.
            </Text>
            <Box display="flex" textAlign="right" gap={1}>
              {FOOTER_SOCIAL_LINKS.map((item, index) => (
                <IconButton
                  key={index}
                  sx={{ p: 0, ml: 2 }}
                  onClick={() => {
                    handleOpenInNewTab(item.link)
                  }}
                >
                  <SvgImage name={item.icon} width={32} height={32} />
                </IconButton>
              ))}
            </Box>
          </Footer>

        </Container>
      </Wrapper >
    </>
  )
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'isMobile'
})<{ isFullScreen?: boolean, isMobile?: boolean }>(
  ({ isFullScreen, isMobile }) => ({
    background:
      isFullScreen && !isMobile
        ? 'linear-gradient(to bottom, #F9F9FD 50%, #1513C3 50%)'
        : 'linear-gradient(to bottom, #F9F9FD 50vh, #1513C3 50vh)',
    display: 'flex',
    flexDirection: 'column',
    height: isFullScreen && !isMobile ? '100vh' : '100%'
  })
)

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: 1
})

const Footer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
  paddingTop: '3vh',
  paddingBottom: '3vh'
})

const Background = styled('img')({
  backgroundRepeat: 'no-repeat',
  position: 'fixed',
  zIndex: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '50%'
})
