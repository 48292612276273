import { Box, Typography, Link } from '@mui/material'

import { MButton, ProgressLoaders, SvgImage } from 'components'
import { MESSAGES, RATES_LINK, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { isNil, last } from 'lodash'

export const UtilityBill: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail, billing } =
    useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [unbilledCharges, setUnbilledCharges] = useState<boolean>(false)

  const handleDownloadBill = (): void => {
    const billDisplayURL = last(billing?.billing)?.billDisplayURL
    if (!isNil(billDisplayURL) && billDisplayURL !== '') {
      window.open(billDisplayURL, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.VIEW_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  useEffect(() => {
    if (((account?.myAccount?.unbilledCharges?.payments) != null && (account?.myAccount?.unbilledCharges?.payments) !== '0') || ((account?.myAccount?.unbilledCharges?.adjustments) != null && (account?.myAccount?.unbilledCharges?.adjustments) !== '0') || ((account?.myAccount?.unbilledCharges?.lateFees) != null && (account?.myAccount?.unbilledCharges?.lateFees) !== '0')) {
      setUnbilledCharges(true)
    }
  }, [account])


  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleViewRates = (): void => {
    window.open(RATES_LINK, '_blank')
  }

  const billStartDate = account?.myAccount?.billStartDt ?? null
  const billEndDate = account?.myAccount?.billEndDt ?? null
  const formattedDateRange = billStartDate !== null && billEndDate !== null
    ? `${dayjs(billStartDate).format('M/DD/YYYY')} to ${dayjs(billEndDate).format('M/DD/YYYY')}`
    : 'No bill available for this account'

  return (
    <Box bgcolor="#ffffff" pb={5}>
      {account !== null
        ? <>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={3} borderBottom='solid 1px #E5E5F6'>
            <Box>
              <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight={600}>
                Account Billing Summary
              </Typography>
              <Typography display="inline" color="#5D6365" fontSize={12}>
                Account Number:
              </Typography>
              <Typography display="inline" fontSize={12} color='#010B0E' fontWeight={600} ml={0.5}>{account?.myAccount?.accountId}</Typography>
              <Box>
                <Typography display="inline" color="#5D6365" fontSize={12}>
                  Residential Service Bill Period:
                </Typography>
                <Typography display="inline" fontSize={12} color='#010B0E' fontWeight={600} ml={0.5}>
                  {formattedDateRange}
                </Typography>
              </Box>
            </Box>
            {!isMobile && <Box display="flex" alignItems="center" gap={2}>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<SvgImage name="ServiceThreeIcon" width={25} height={25} />}
                onClick={handleViewRates}
              >
                View Rates
              </MButton>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<SvgImage name="BillDownloadPdf" width={25} height={25} />}
                onClick={handleDownloadBill}
                disabled={isNil(billing)}
              >
                View PDF File
              </MButton>
            </Box>}
          </Box>
          <Box px={isMobile ? 2 : 3}>
            {((account?.myAccount?.previousBalance) != null) && <BillingEntry title='Previous Balance' amount={account?.myAccount?.previousBalance} />}
            {((account?.myAccount?.latestPayment) != null) && <BillingEntry title='Payments Received' amount={account?.myAccount?.latestPayment} />}
            {((account?.myAccount?.latestPayment) != null) && <BillingEntry title='Balance Forward' amount={(Number(account?.myAccount?.previousBalance) + Number(account?.myAccount?.latestPayment)).toString()} />}
            {((account?.myAccount?.latestBillAmount) != null) && <BillingEntry title='Total Current Charges' amount={account?.myAccount?.latestBillAmount} />}
            {((account?.myAccount?.endingBalOnBill) != null) && <BillingEntry title='Balance Due' amount={account?.myAccount?.endingBalOnBill} />}
            {((account?.myAccount?.lateFees) != null && (account?.myAccount?.lateFees) !== '0') && <BillingEntry title='Late Payment/Penalty Fees' amount={account?.myAccount?.lateFees} />}
            {((account?.myAccount?.adjustments) != null && (account?.myAccount?.adjustments) !== '0') && <BillingEntry title='Adjustments' amount={account?.myAccount?.adjustments} />}

            {unbilledCharges && <Typography fontSize={14} fontWeight={700} color="#010B0E" mt={2}>Recent Activity</Typography>}
            {((account?.myAccount?.unbilledCharges?.payments) != null && (account?.myAccount?.unbilledCharges?.payments) !== '0') && <BillingEntry title='Payments' amount={account?.myAccount?.unbilledCharges?.payments} />}
            {((account?.myAccount?.unbilledCharges?.adjustments) != null && (account?.myAccount?.unbilledCharges?.adjustments) !== '0') && <BillingEntry title='Adjustments' amount={account?.myAccount?.unbilledCharges?.adjustments} />}
            {((account?.myAccount?.unbilledCharges?.lateFees) != null && (account?.myAccount?.unbilledCharges?.lateFees) !== '0') && <BillingEntry title='Late Fees' amount={account?.myAccount?.unbilledCharges?.lateFees} />}

            <Box display="flex" justifyContent="space-between" bgcolor='#EEF3F6' px={3} py={2}>
              <Typography fontSize={16} fontWeight={700} color="#010B0E">Actual Account Balance</Typography>
              <Typography fontSize={16} fontWeight={700} color="#010B0E">{((account?.myAccount?.accountBalance) != null) && formatValue(account?.myAccount?.accountBalance, true, true, true)}</Typography>
            </Box>
            <Typography display="flex" color="#5D6365" fontSize={isMobile ? '14px' : '18px'} my={2} textAlign={isMobile ? 'center' : 'left'}>
              Need a Payment Plan? Contact us at
              <Link
                ml={0.5}
                href="tel:8443416469"
                target="_blank"
                rel="noreferrer"
                color='#1513C3'
                style={{ textDecoration: 'none' }}
              >
                844-341-6469
              </Link>
              .
            </Typography>
          </Box>
        </>
        : <Box pt={5}> <ProgressLoaders height='100%' /> </Box>}
    </Box >
  )
}

interface BillingEntryProps {
  title: string
  amount: string
}

const BillingEntry: React.FC<BillingEntryProps> = (props: BillingEntryProps) => {
  const isMobile = useMobileBreakpoints()
  return <Box display="flex" justifyContent="space-between" borderBottom='solid 1px #E5E5F6' px={3} py={2}>
    <Typography fontSize={14} color="#010B0E" width={isMobile ? '70%' : 'auto'}>{props.title}</Typography>
    <Typography fontSize={14} color="#010B0E">{formatValue(props.amount, true, true, true)}</Typography>
  </Box>
}
