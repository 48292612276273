import { Box, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { MButton, ProgressLoaders, SvgImage, Text } from 'components'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { formatValue, screenType, useLocalStorage, useWindowSize } from 'utils'
import { exportExcel } from 'utils/helpers/exportExcel'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ItemWrapper, Wrapper } from './BillingHistory'
import { NoData } from './NoData'

export const PaymentHistory: React.FC = () => {
  const { currentAccount, payments, getPayments, isAdmin, userEmail, account } = useAuth()
  const { width } = useWindowSize()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === payments?.accountId) {
      return
    }
    void getPayments({
      AccessToken: authToken,
      accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
      startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      admin: isAdmin,
      email: userEmail
    })
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])


  const handleExportExcel = (): void => {
    const excelData = [
      ['Date', 'Amount', 'Status'],
      ...(payments?.payment?.map((payment) => [payment.paymentDate, payment.paymentAmount, payment.paymentTenderType]) ?? [])
    ]
    exportExcel(excelData, 'Payment History', 'payment_history_data.xlsx')
  }

  return (
    <Wrapper>
      {(payments?.payment !== null) && payments?.payment.length === 0 && (
        <NoData transactionType="payment" />
      )}
      {payments !== null
        ? payments?.payment.length !== 0 && <Box mb="20px">
          {!isMobile && <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
            <MButton variant="text" texttransform="none" onClick={handleExportExcel} endIcon={<SvgImage name="DownloadIcon" width={15} height={15} />}>
              <Text color="#1513C3" fontSize="17px" margin={screenType(width, 'sm') ? '0 10px 0 0' : '0 10px 0 0'}>Export to Excel</Text>
            </MButton>
          </Box>}
          {isMobile
            ? <Box mb={3}>
              {payments?.payment.slice().reverse().map((payment, index) => (
                <PaymentEntryMobile key={index} date={payment.paymentDate} amount={payment.paymentAmount} method={payment.paymentTenderType} />
              ))}
            </Box>
            : <Box>
              <BillingHeader>
                <ItemWrapper>Payment Date</ItemWrapper>
                <ItemWrapper>Payment Amount </ItemWrapper>
                <ItemWrapper>Payment Method</ItemWrapper>
              </BillingHeader>
              {payments?.payment.slice().reverse().map((payment, index) => (
                <BillItem key={index}>
                  <ItemWrapper small="true">{dayjs(payment.paymentDate).format('MM-DD-YYYY')}</ItemWrapper>
                  <ItemWrapper small="true">
                    {formatValue(payment.paymentAmount)}
                  </ItemWrapper>
                  <ItemWrapper small="true">{payment.paymentTenderType}</ItemWrapper>
                </BillItem>
              ))}
            </Box>
          }
          {isMobile && <MButton
            sx={{ width: '100%' }}
            size="large"
            variant="outlined"
            rounded="true"
            texttransform="none"
            onClick={handleExportExcel}
          >
            Export to Excel
          </MButton>}
        </Box>
        : <ProgressLoaders height="100%" />}
    </Wrapper>
  )
}

export const BillingHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 30px',
  background: 'rgba(132, 176, 218, 0.1)'
})

export const BillItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 30px',
  borderBottom: '1px solid #eee'
})

interface PaymentEntryMobileProps {
  date: string
  amount: string
  method: string
}

const PaymentEntryMobile: React.FC<PaymentEntryMobileProps> = (props: PaymentEntryMobileProps) => {
  return <Box py={2} borderBottom="solid 1px #EFEFFA">
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Payment Date</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}>{dayjs(props.date).format('MM-DD-YYYY')}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Payment Amount</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}>{formatValue(props.amount)}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Payment Method</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}>{props.method}</Typography>
    </Box>
  </Box>
}
