export function formatValue(
  balance: string,
  includeDecimals: boolean = true,
  includeCurrencySymbol: boolean = true,
  isAbsolute: boolean = false,
  useComma: boolean = true
): string {
  const numBalance = Number(balance)

  if (isNaN(numBalance)) {
    return balance
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatToAbsolute = (balance: number) => isAbsolute ? Math.abs(balance) : (balance)

  const isNegative = numBalance < 0
  const formattedBalance = formatToAbsolute(numBalance).toFixed(2)

  const formattedValue = includeDecimals
    ? formattedBalance
    : Math.round(formatToAbsolute(numBalance)).toString()
  const currencyValue = isNegative
    ? `-$${formattedValue}`
    : `$${formattedValue}`

  const formattedNumber = includeCurrencySymbol ? currencyValue : formattedValue

  if (useComma) {
    const commaFormattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return commaFormattedNumber
  } else {
    return formattedNumber
  }
}
