import { Box } from '@mui/material'
import { mapLoader } from 'components/domain/GoogleMaps'
import type React from 'react'
import { useEffect } from 'react'

const PaymentLocationMap: React.FC = () => {
    useEffect(() => {
        mapLoader.importLibrary('maps').then(({ Map, InfoWindow }) => {
            const map = new Map(document.getElementById('map') as HTMLElement, {
                center: { lat: 33.9133651, lng: -117.2622723 },
                zoom: 12,
                mapId: 'DEMO_MAP_ID',
                streetViewControl: false,
                mapTypeControl: false
            })
            const infoWindowContent = `
                <div>
                    <div style="display: flex; justify-content: space-between; width: 100%">
                        <div style="text-align: left; width: 75%">
                            <div><b>Moreno Valley Electrical Utility</b></div>
                            <div>14331 Frederick St, Suite 2, Moreno Valley, CA 92553</div>
                            <div>Monday - Thursday 7:30AM - 5:30PM</div>
                            <div>Friday 8:00AM - 5:00PM</div>
                            <div>(844) 341 - 6469</div>
                            <a href="https://maps.google.com/maps?ll=33.914131,-117.26157&z=14&t=m&hl=en-US&gl=AR&mapclient=embed&cid=10838033034074655846"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View larger map
                        </a>
                        </div>
                        <div style="text-align: right; width: 25%">
                            <a style="color: #3a84df; display: block; height: 43px; text-decoration: none; width: 54.7344px" target="_blank" href="https://maps.google.com/maps/dir//Moreno+Valley+Electrical+Utility+14331+Frederick+St+Moreno+Valley,+CA+92553/@33.9141319,-117.2614843,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x80dca67383053b67:0x96686d9f43f80c66" rel="noreferrer">
                            <div style="background: rgba(0, 0, 0, 0) url(https://maps.gstatic.com/mapfiles/embed/images/entity11.png) no-repeat; height: 22px; width: 22px; margin-right: auto; margin-left: auto"></div>    
                            Directions
                            </a>
                        </div>
                    </div>
                </div>
            `
            const infoWindow = new InfoWindow({
                content: infoWindowContent,
                disableAutoPan: true
            })
            mapLoader.importLibrary('marker').then(({ AdvancedMarkerElement }) => {
                const marker = new AdvancedMarkerElement({
                    map,
                    position: { lat: 33.91413, lng: -117.26157 },
                    title: 'Moreno Valley Electrical Utility'
                })
                infoWindow.open(map, marker)
                return marker
            }).catch((e) => { console.log(e) })
        }).catch((e) => { console.log(e) })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box id="map" flex={1} />
    )
}

export default PaymentLocationMap
