import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F9F9FD;
`

export const Center = styled(Box)`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
