import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const NemModal = ({ open, setIsOpen }: any) => {
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="lg"
            onClose={() => setIsOpen(false)}
        >
            <DialogTitle>Attention NEM 1.0 (Solar) Customers</DialogTitle>
            <DialogContent>
                <DialogContentText mb={1}>
                    As a NEM 1.0 (Solar) customer, you are entitled to a credit for the surplus electricity
                    supplied to Moreno Valley Utility&apos;s system. This surplus credit is applied to your annual
                    solar bill to offset the costs associated with your energy consumption, and you are
                    entitled to receive a reimbursement for any net surplus energy remaining at the end of the
                    twelve-month billing period.
                </DialogContentText>
                <DialogContentText mb={1}>
                    Your account bypassed the true-up process at the end of its anniversary date. Therefore,
                    we will reconcile your account on your November 2023 bill. MVU will apply net surplus
                    credits to any other outstanding fees and issue a refund for the remaining credit.
                </DialogContentText>
                <DialogContentText mb={1}>
                    Your account may have unpaid charges after the reconciliation process. If so, it will be
                    subject to late fees, notices, and, ultimately, disconnection if fees remain unpaid past
                    the due date of your November 2023 bill (per Rules 8, 9, and 11 of Moreno Valleyâ€™s
                    Electric Service Rules, Fees and Charges). You will continue to receive a monthly bill
                    containing all standard charges, including the public purpose charges, service fees, and
                    applicable taxes, which are due upon receipt of the monthly bills.
                </DialogContentText>
                <DialogContentText mb={1}>
                    Please note that your November 2023 bill will include the Solar Net Metering Summary
                    showing your account balance due, which is subject to late fees and disconnection if
                    unpaid.
                </DialogContentText>
                <DialogContentText mb={1}>
                    If you need more help, including payment plan options or assistance programs, please
                    contact our Customer Service department at (844) 341-6469.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default NemModal
