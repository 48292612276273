import { Link } from '@mui/material'
import { AlertDialog, SvgImage } from 'components/ui'
import { MESSAGES } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { screenType, useWindowSize } from 'utils'
import { CloseButton, ICModal, ICModalContent } from './CloudPay'
import { PayBillDialog } from './PayBillDialog'

interface IInvoiceCloudFrame {
  page?: number
  children: React.ReactNode
}

export const InvoiceCloudFrame = ({ page, children }: IInvoiceCloudFrame): JSX.Element => {
  const { width } = useWindowSize()
  const { enqueueSnackbar } = useSnackbar()
  const [visible, setVisible] = useState<boolean>(false)
  const [refresher, setRefresher] = useState<number>(1)
  const { icAuth } = useAuth()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isPaperlessDialogOpen, setIsPaperlessDialogOpen] = useState<boolean>(false)


  const handleDialog = (): void => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleShow = (): void => {
    if (!icAuth && page !== 0) {
      enqueueSnackbar(MESSAGES.IC_RETRIEVE_ERROR, {
        variant: 'error'
      })
    } else if (icAuth) {
      setVisible(true)
    }

    if (!page) {
      setVisible(false)
    }
  }

  const handleHide = (): void => {
    setVisible(false)
    setRefresher(refresher + 1)
    if (page === 1) {
      setIsDialogOpen(true)
    } else if (page === 2) {
      setIsPaperlessDialogOpen(true)
    } else if (page === 9) {
      setIsDialogOpen(true)
    }
  }

  const iframeUrl = `https://www.invoicecloud.com/portal/remoteauth/customerportalredirect.aspx?RemoteAuthenticationToken=${icAuth}&ViewMode=1&RedirectID=${page}&CSSUrl=${process.env.REACT_APP_IC_STYLESHEET_URL}`

  return (
    <>
      <Link
        onClick={handleShow}
      >
        {children}
      </Link>

      <ICModal visible={visible ? 'true' : 'false'} onClick={handleHide}>
        <ICModalContent
          ismobile={screenType(width, 'sm') ? 'true' : 'false'}
          onClick={(e) => { e.stopPropagation() }}
        >
          <CloseButton
            sx={{ margin: screenType(width, 'sm') ? '5px' : '0' }}
            onClick={handleHide}
          >
            <SvgImage name="CloseIcon" width={24} height={24} />
          </CloseButton>
          {icAuth &&
            (screenType(width, 'sm')
              ? (
                <iframe
                  key={refresher}
                  frameBorder="0"
                  src={iframeUrl}
                  width="100%"
                  height="90%"
                  title="InvoiceCLoud Frame"
                />
              )
              : (
                <iframe
                  key={refresher}
                  frameBorder="0"
                  src={iframeUrl}
                  width="100%"
                  height="550"
                  title="InvoiceCLoud Frame"
                />
              ))}
        </ICModalContent>
      </ICModal>
      <PayBillDialog isOpen={isDialogOpen} handleDialog={handleDialog} />
      <AlertDialog open={isPaperlessDialogOpen} handleClick={() => { setIsPaperlessDialogOpen(!isPaperlessDialogOpen) }} type="info" message={MESSAGES.IC_PAPERLESS_INFO} />
    </>
  )
}
