export const ENDPOINTS = {
  BASE_URL: process.env.REACT_APP_API_URL,
  REGISTER: '/register',
  SERVICE_REQUEST: '/servicerequest',
  UPDATE: '/update',
  USAGE: '/usage',
  USER: '/user',
  CLOUDPAY: '/cloudpay',
  USER_LIST: '/userlist',
  CONTACT_US: '/contactus',
  OUTAGES: '/outages',
  USER_ADMIN: '/user-admin',
  VERIFY_ACCOUNT: '/verifyAccount',
  CHECK_EMAIL: '/checkEmail',
  CONFIRM_REGISTER: '/register/confirm',

  // ADMIN APIS
  REGISTER_ADMIN: '/registerAdmin',
  ADMIN_USER_LIST: '/adminuserlist',
  GET_ADMIN_USER: '/getadminuser',
  ADMIN: '/admin',
  ADMIN_USER: '/admingetuser',

  ACCOUNT: '/v2/account',
  IC_AUTH: '/v2/auth/ic',
  BILLING: '/v2/history/billing',
  USAGE_V2: '/v2/usage',
  PAYMENT: '/v2/history/payment',
  UPDATE_MAIL_ADDRESS: 'v2/mailingAddress',
  VERIFY_PAY_BILL: '/v2/payment/verify',
  RETRIEVE_IC_ACCOUNT: 'v2/invoiceCloud/retrieve',
  REPORT_OUTAGE: '/v2/outage/report',

  ACCOUNT_AUTH_ADMIN: '/v2/admin/account',
  IC_AUTH_ADMIN: '/v2/admin/auth/ic',
  BILLING_AUTH_ADMIN: '/v2/admin/history/billing',
  USAGE_V2_AUTH_ADMIN: '/v2/admin/usage',
  PAYMENT_AUTH_ADMIN: '/v2/admin/history/payment',
  UPDATE_MAIL_ADDRESS_AUTH_ADMIN: 'v2/admin/mailingAddress',
  UPDATE_AUTH_ADMIN: 'admin/update'

}
