import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Link, Typography } from '@mui/material'
import { Text } from 'components'
import Accordion from 'components/ui/Accordion'
import { SERVICE_ITEMS, ServicesFooterButtonData } from 'config'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'


export const Services: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box p={isMobile ? 3 : 5} mt={3} mb={isMobile ? 10 : '26vh'} bgcolor="#ffffff">
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
        <Box>
          <Typography fontSize={isMobile ? 18 : 24} fontWeight={600} color="#010B0E">
            Need to start or make changes to your service?
          </Typography>
          <Typography fontSize={14} color="#5D6365">
            Select an option below to find our next steps. You can also make changes to your service by contacting customer service at 1-844-341-6469.
          </Typography>
          <Box my={2}>
            {SERVICE_ITEMS.map((item, index) => {
              return <Accordion key={index} title={item.title}>
                <Typography fontSize={isMobile ? 12 : 14}>
                  {item.content !== undefined && item.content !== '' && (
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  )}
                  {item.list != null && (
                    <Box ml={2}>
                      <ul>
                        {item.list.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Box>
                  )}
                  {item.subcontent != null && item.subcontent}
                </Typography>
              </Accordion>
            })}
          </Box>
        </Box>
      </Box>
      {ServicesFooterButtonData.map((item, index) => (
        <ServiceFooterButton key={index} title={item.title} desc={item.desc} link={item.link} linkTitle={item.linkTitle} icon={item.icon} />
      ))}
    </Box>
  )
}

interface ServiceFooterProps {
  title: string
  desc: string
  link: string
  linkTitle: string
  icon: any
}

export const ServiceFooterButton: React.FC<ServiceFooterProps> = (props: ServiceFooterProps) => {
  const navigate = useNavigate()
  return <Box>
    <Box p={2} bgcolor="#F9F9FD" borderRadius={2}>
      <props.icon sx={{ color: '#3532F4' }} />
      <Text color="#010B0E" fontSize="18px" margin="12px 0 4px">
        {props.title}
      </Text>
      <Text color="#5D6365" fontSize="14px" margin="0 0 12px">
        {props.desc}
      </Text>
      <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(props.link) }}>
        <Box display="flex" alignItems="center">
          <Text color="#1513C3" fontSize="12px" margin="0 4px 0 0">
            {props.linkTitle}
          </Text>
          <ArrowRightAltIcon sx={{ color: '#3532F4' }} />
        </Box>
      </Link>
    </Box>
  </Box>
}
