import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Accordion as MUIAccordion,
    Typography
} from '@mui/material'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface AccordionProps {
    key?: number
    title: string
    children?: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const isMobile = useMobileBreakpoints()
    const handleChange = (): void => {
        setExpanded(!expanded)
    }
    return (
        <MUIAccordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
                backgroundColor: '#EFEFFA',
                width: '100%'
            }}
        >
            <AccordionSummary
                expandIcon={
                    <IconButton
                        size="small"
                        sx={{
                            display: 'flex',
                            bgcolor: '#E5E5F6',
                            '&:hover': { opacity: 1, bgcolor: '#E5E5F6' }
                        }}
                    >
                        {expanded
                            ? (<RemoveIcon sx={{ color: '#3532F4' }} />)
                            : (<AddIcon sx={{ color: '#3532F4' }} />)}
                    </IconButton>
                }
            >
                <Typography color="#010B0E" fontSize={isMobile ? 14 : 16} mr={2} p={isMobile ? 0 : 1}>
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    background: '#FBFBFB',
                    py: 2,
                    px: 3,
                    borderLeft: '4px solid #7270F7',
                    '.accordion-link': {
                        color: '#1513C3'
                    },
                    ul: {
                        paddingLeft: '0'
                    },
                    '.accordion-list': {
                        listStyleType: 'none',
                        padding: '0'
                    },
                    '.accordion-list::before': {
                        content: "''",
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: '#7270F7',
                        marginRight: '8px'
                    }
                }}
            >
                {props.children}

            </AccordionDetails>
        </MUIAccordion>
    )
}

export default Accordion
