import { Box, OutlinedInput } from '@mui/material'
import { ErrorText } from 'components'
import type React from 'react'
import { Controller, type Control, type FieldErrors } from 'react-hook-form'
import { FormLabel } from '../FormLabel/FormLabel'


interface TextInputProps {
    fieldName: string
    fieldLabel: string
    fieldType: string
    control: Control<any>
    errors: FieldErrors<any>
    placeholder?: string
    fullWidth?: boolean
    multiline?: boolean
}

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    return <Controller
        name={props.fieldName}
        control={props.control}
        render={({ field: { onChange, value, name } }): JSX.Element => (
            <Box mb={2} width={(props.fullWidth === true) ? '100%' : 'auto'}>
                <FormLabel>{props.fieldLabel.toUpperCase()}</FormLabel>
                <OutlinedInput
                    name={name}
                    placeholder={props.placeholder ?? `Enter your ${props.fieldLabel.toLowerCase()}`}
                    onChange={onChange}
                    value={value}
                    type={props.fieldType}
                    fullWidth
                    multiline={props.multiline}
                />
                {(props.errors[props.fieldName] != null) && (
                    <ErrorText>{String(props.errors[props.fieldName]?.message)}</ErrorText>
                )}
            </Box>
        )}
    />
}
