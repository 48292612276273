import InfoIcon from '@mui/icons-material/Info'
import { Box } from '@mui/material'
import { AuthLayout, MButton, Text } from 'components'
import { ROUTES_PATH } from 'config'
import { AuthWrapper } from 'containers/Auth'
import { FindUs } from 'containers/Contact/Components'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const ContactUs: React.FC = () => {
  const navigate = useNavigate()
  const isMobile = useMobileBreakpoints()

  const handleLogin = (): void => {
    navigate(ROUTES_PATH.SIGNIN)
  }

  return (
    <AuthLayout>
      <AuthWrapper>
        <Box bgcolor='white' width="100%" px={isMobile ? 3 : 8} py={3}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            mb={4}
            bgcolor='#F9F9FD'
            p={3}

          >
            <InfoIcon
              sx={{
                color: '#C2241D',
                fontSize: '40px'
              }}
            />
            <Text
              color="#010B0E"
              fontSize="24px"
              fontWeight="600"
              align="center"
              margin="16px 0 24px"
            >
              If you want to submit a Contact Us form, please log into your
              account.
            </Text>
            <MButton
              variant="contained"
              size="large"
              rounded="true"
              texttransform="none"
              sx={{
                width: '200px'
              }}
              onClick={handleLogin}
            >
              Log In
            </MButton>
          </Box>
          <FindUs isAuthenticated={false} />
        </Box>
      </AuthWrapper>
    </AuthLayout>
  )
}
