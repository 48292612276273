import 'dayjs/locale/en'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'

import {
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  styled
} from '@mui/material'

import { MButton, ProgressLoaders, Text } from 'components'
import { MESSAGES, ROUTES_PATH, TOPIC_ITEMS, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { isEmpty } from 'lodash'

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { account, getAccount, currentAccount, isAdmin, userEmail } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [form, setForm] = useState<any>({
    name: '',
    billingID: '',
    accountNumber: '',
    serviceAddress: '',
    email: '',
    number: '',
    topic: '',
    subject: '',
    file: '',
    comments: ''
  })
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (account != null) {
      setForm({
        name: account.myAccount.personName,
        accountNumber: account.myAccount.accountId,
        serviceAddress: account.myAccount.serviceAddresses?.[0].serviceAddress,
        email: account.myAccount.email,
        number: account?.myAccount?.mobileNumber ?? account?.myAccount?.alternateNumber
      })
    }
  }, [account])

  const handleChange = (e: any, name: string): void => {
    if (name === 'name' && !isEmpty(account?.myAccount?.personName)) return
    else if (name === 'accountNumber' && !isEmpty(account?.myAccount.accountId)) return
    else if (name === 'serviceAddress' && !isEmpty(account?.myAccount.serviceAddresses?.[0].serviceAddress)) return
    else if (name === 'email' && !isEmpty(account?.myAccount.email)) return
    else if (name === 'number' && !isEmpty(account?.myAccount.alternateNumber)) return

    setForm({ ...form, [name]: e.target.value })
  }

  const validateForm = (): boolean => {
    try {
      let error
      // ensure required fields have values
      if (form.name === '') {
        error = MESSAGES.REQUIRED_NAME_ERROR
      } else if (form.email === '') {
        error = MESSAGES.REQUIRED_EMAIL_ERROR
      } else if (form.number === '') {
        error = MESSAGES.REQUIRED_TELEPHONE_ERROR
      } else if (form.number.replace(/([()_-])/g, '').length !== 11) {
        error = MESSAGES.INVALID_TELEPHONE_ERROR
      } else if (form.topic === '' || form.topic === 'default') {
        error = MESSAGES.SELECT_ONE_ERROR
      } else if (form.subject === '') {
        error = MESSAGES.REQUIRED_SUBJECT_ERROR
      }

      // throw snackbar error if something is missing
      if (error) {
        enqueueSnackbar(error, {
          variant: 'error'
        })
        return false
      }

      return true
    } catch (e: any) {
      return false
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      try {
        const body = {
          ...form,
          AccessToken: authToken
        }

        await APIs.contactUs(body)
        enqueueSnackbar(MESSAGES.FORM_SUBMIT_SUCCESS, {
          variant: 'success'
        })
        setTimeout(() => {
          navigate(ROUTES_PATH.DASHBOARD)
        }, 7000)
      } catch (e) {
        enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
          variant: 'error'
        })
      }
    }
  }

  return (
    <>
      {account !== null
        ? <Box display="flex" flexDirection="column" flex={1} gap={3} mr={2}>
          <Box>
            <Text fontSize="22px" fontWeight="700" color="#010B0E">
              Contact Us Form
            </Text>
            <span style={{ color: '#010B0E', fontSize: '16px', fontWeight: '900' }}>
              {' '}
              Please allow 2-3 business days for response.
            </span>
          </Box>
          <Box>
            <FormLabel>Name (Last, First)*</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Customer Name"
              value={form.name}
              onChange={(e) => {
                handleChange(e, 'name')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Account Number </FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Account Number"
              value={form.accountNumber}
              onChange={(e) => {
                handleChange(e, 'accountNumber')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Service Address</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Service Address"
              value={form.serviceAddress}
              onChange={(e) => {
                handleChange(e, 'ServiceAddress')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Email Address *</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Email Address"
              value={form.email}
              onChange={(e) => {
                handleChange(e, 'email')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Contact Number *</FormLabel>
            <InputMask
              mask="(999) 999-9999"
              value={form.number}
              maskChar="_"
              type="text"
              onChange={(e: any) => {
                handleChange(e, 'number')
              }}
            >
              <OutlinedInput fullWidth placeholder="Contact Number" />
            </InputMask>
          </Box>
          <Box>
            <FormLabel>Topic *</FormLabel>
            <Select fullWidth displayEmpty defaultValue=""
              onChange={(e) => {
                handleChange(e, 'topic')
              }}>
              <MenuItem value="" disabled>
                Select a topic
              </MenuItem>
              {TOPIC_ITEMS.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormLabel>Subject *</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Subject"
              value={form.subject}
              onChange={(e) => {
                handleChange(e, 'subject')
              }}
            />
          </Box>
          <Box>
            <FormLabel>ADDITIONAL COMMENTS (OPTIONAL)</FormLabel>
            <Textarea
              placeholder="Additional Comments"
              value={form.comments}
              onChange={(e) => {
                handleChange(e, 'comments')
              }}
            />
            <span style={{ color: '#5D6365', fontSize: '12px', fontWeight: '400' }}>
              (*) Indicates a required field
            </span>
          </Box>
          <Box>
            <MButton
              variant="contained"
              rounded="true"
              texttransform="none"
              onClick={handleSubmit}
              size="large"
            >
              Submit Form
            </MButton>
          </Box>
        </Box>
        : <Box my={2}><ProgressLoaders height="100%" /></Box>}
    </>
  )
}

export const Textarea = styled(TextareaAutosize)({
  width: '100%',
  height: '188px !important',
  resize: 'none',
  borderRadius: '2px',
  border: '2px solid #E5E5F6 !important',
  padding: '10px',
  font: 'inherit'
})

export const FormLabel = styled(InputLabel)`
  font-weight: 400;
  font-size: 12px;
  color: #4d515f;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
`
