import {
  Box,
  Link,
  Typography
} from '@mui/material'
import Accordion from 'components/ui/Accordion'
import { BILLING_QUESTIONS, CONTACT_SOCIAL_ITEMS, ROUTES_PATH } from 'config'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Wrapper } from './BillingHistory'
import BillingQuestionsLink from './BillingQuestionsLink'

export const BillingQuestions: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const navigate = useNavigate()

  return (
    <Box>
      <Wrapper>
        <Typography color="#010B0E" fontSize={18} fontWeight={600} my={2}>
          Frequently Asked Billing Questions
        </Typography>
        <Box>
          {BILLING_QUESTIONS.map((item, index) => (
            <Accordion key={index} title={item.title}>
              <Typography fontSize={isMobile ? 12 : 14}>
                {item.content}
              </Typography>
            </Accordion>
          ))}
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" py={isMobile ? 2 : 5} >
            <Typography fontSize={18} fontWeight={600} color="#010B0E">Have any additional questions?
              <Link mt={isMobile ? 1 : 0} ml={isMobile ? 0 : 1} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.CONTACT) }}>
                Contact Us
              </Link>
            </Typography>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mt={isMobile ? 2 : 0}>
              <Typography fontSize={18} fontWeight={600} color="#010B0E">Find us at:</Typography>
              <Box display="flex" flexDirection="row" gap={2} px={isMobile ? 0 : 3} py={isMobile ? 3 : 0}>
                {CONTACT_SOCIAL_ITEMS.slice(0, 3).map((social, index) => {
                  return (
                    <BillingQuestionsLink key={index} social={social} index={index} />
                  )
                })}
              </Box>
            </Box>

          </Box>
        </Box>
      </Wrapper >
    </Box >
  )
}
