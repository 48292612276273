import styled from '@emotion/styled'
import { InputLabel } from '@mui/material'

export const FormLabel = styled(InputLabel)({
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginBottom: 5,
    whiteSpace: 'normal',
    wordWrap: 'break-word'
})
