import { yupResolver } from '@hookform/resolvers/yup'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Avatar,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@mui/material'
import { Auth } from 'aws-amplify'
import { MButton, ProgressLoaders } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import {
  MESSAGES,
  ROUTES_PATH,
  USER_TOKEN
} from 'config'
import { FormLabel } from 'containers/Contact/Components'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'
import { NotificationPreferences, PaperlessBilling } from './components'
import UpdateEmail from './components/UpdateEmail'


interface ShowPasswordType {
  current: boolean
  new: boolean
  confirmNew: boolean
}

const schema = yup.object({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string().required('New password is required')
    .notOneOf([yup.ref('currentPassword')], 'New password must be different from the current password'),
  passwordConfirm: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match')
}).required()

interface IResetPwdForm {
  currentPassword: string
  newPassword: string
  passwordConfirm: string
}

export const AccountPreferences: React.FC = () => {
  const { account, currentAccount, getAccount, handleLogOut, isAdmin, userEmail } =
    useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [showPassword, setShowPassword] = useState<ShowPasswordType>({
    current: false,
    new: false,
    confirmNew: false
  })

  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [isPrefUpdated, setIsPrefUpdated] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IResetPwdForm>({
    mode: 'all',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (isPrefUpdated) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId ?? '',
        admin: isAdmin,
        email: userEmail
      })
      setIsPrefUpdated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefUpdated])

  const onSubmit = async (data: IResetPwdForm): Promise<void> => {
    if (isAdmin) {
      enqueueSnackbar(MESSAGES.ADMIN_ACCESS_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }

    if (data.passwordConfirm !== data.newPassword) {
      enqueueSnackbar(MESSAGES.PASSWORD_MATCH_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }

    if (data.passwordConfirm === data.newPassword) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(
          user,
          data.currentPassword,
          data.newPassword
        )
        enqueueSnackbar(MESSAGES.PASSWORD_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
        void handleLogout()
      } catch (e: any) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    }
  }


  const handleLogout = async (): Promise<void> => {
    await handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
  }


  return (
    <>
      {isMobile && <QuickLinks />}
      {account !== null
        ? <Box mt={3} p={3} bgcolor="#ffffff" borderRadius="4px">
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
            <Box display="flex" flexDirection="column" flex={1} alignContent="space-between" gap={2}>
              <PaperlessBilling setIsPrefUpdated={setIsPrefUpdated} />
              <UpdateEmail />
            </Box>
            <Box flex={1} border='1px solid #E5E5F6' borderRadius="4px" p={3}>
              <form method="post" onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" alignItems="center" flexDirection={isMobile ? 'row' : 'column'} gap={2}>
                  <Avatar sx={{ bgcolor: '#F3FBFD', width: isMobile ? 50 : 80, height: isMobile ? 50 : 80 }}>
                    <LockOutlinedIcon fontSize={isMobile ? 'small' : 'large'} sx={{ color: '#1513C3' }} />
                  </Avatar>
                  <Typography color="#010B0E" fontWeight={600} fontSize={isMobile ? 18 : 24}>
                    Change Password
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" mt={4} gap={3}>
                  <Box>
                    <FormLabel>CURRENT PASSWORD</FormLabel>
                    <Controller
                      name="currentPassword"
                      control={control}
                      render={({ field: { onChange, value, name } }): JSX.Element => (
                        <OutlinedInput
                          fullWidth
                          size="small"
                          type={showPassword.current ? 'text' : 'password'}
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder="Enter your current password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword({
                                    ...showPassword,
                                    current: !showPassword.current
                                  })
                                }}
                                edge="end"
                              >
                                {showPassword.current
                                  ? (
                                    <VisibilityOff sx={{ color: '#3532F4' }} />
                                  )
                                  : (
                                    <Visibility sx={{ color: '#3532F4' }} />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <FormLabel>NEW PASSWORD</FormLabel>
                    <Controller
                      name="newPassword"
                      control={control}
                      render={({ field: { onChange, value, name } }): JSX.Element => (
                        <OutlinedInput
                          fullWidth
                          size="small"
                          type={showPassword.new ? 'text' : 'password'}
                          name={name}
                          onChange={onChange}
                          value={value}
                          placeholder="Enter the new password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword({
                                    ...showPassword,
                                    new: !showPassword.new
                                  })
                                }}
                                edge="end"
                              >
                                {showPassword.new
                                  ? (
                                    <VisibilityOff sx={{ color: '#3532F4' }} />
                                  )
                                  : (
                                    <Visibility sx={{ color: '#3532F4' }} />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.newPassword &&
                      <FormHelperText error>{errors.newPassword.message}</FormHelperText>}
                  </Box>
                  <Box>
                    <FormLabel>CONFIRM NEW PASSWORD</FormLabel>
                    <Controller
                      name="passwordConfirm"
                      control={control}
                      render={({ field: { onChange, value, name } }): JSX.Element => (
                        <OutlinedInput
                          fullWidth
                          size="small"
                          type={showPassword.confirmNew ? 'text' : 'password'}
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder="Enter again the new password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword({
                                    ...showPassword,
                                    confirmNew: !showPassword.confirmNew
                                  })
                                }}
                                edge="end"
                              >
                                {showPassword.confirmNew
                                  ? (
                                    <VisibilityOff sx={{ color: '#3532F4' }} />
                                  )
                                  : (
                                    <Visibility sx={{ color: '#3532F4' }} />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.passwordConfirm &&
                      <FormHelperText error>{errors.passwordConfirm.message}</FormHelperText>}
                  </Box>
                  <MButton
                    size="large"
                    variant="contained"
                    rounded="true"
                    type="submit"
                    disabled={!isValid}
                    texttransform="none"
                  >
                    Confirm New Password
                  </MButton>
                </Box>
              </form>
            </Box>
          </Box>

          <NotificationPreferences setIsPrefUpdated={setIsPrefUpdated} />

        </Box >
        : <Box mt={3}><ProgressLoaders height='100%' /></Box>}
    </>

  )
}
