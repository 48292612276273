import { AuthLayout } from 'components'
import { AuthWrapper } from 'containers/Auth'
import { Outages } from 'containers/Outages'
import type React from 'react'

const OutagesPage: React.FC = () => {
    return (
        <AuthLayout>
            <AuthWrapper>
                <Outages />
            </AuthWrapper>
        </AuthLayout>
    )
}

export default OutagesPage
