import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'Roboto', 'sans-serif'].join(',')
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1513C3',
      light: '#8E9293',
      dark: '#0E1396'
    },
    secondary: {
      main: '#8a8e94',
      light: '#b3b3b3',
      dark: '#6f6f6f'
    },
    warning: {
      main: '#DF6F1E'
    },
    error: {
      main: '#C2241D'
    },
    info: {
      main: '#9FB0AF'
    },
    success: {
      main: '#30BD23'
    },
    background: {
      default: '#F3F3F4',
      paper: '#ffffff'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          'box-shadow': 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          'box-shadow': 'none',
          'background-image': 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          'box-shadow': 'none'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          'padding-top': '10px',
          'padding-bottom': '10px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75em'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px'
        },
        root: {
          borderRadius: '2px',
          border: '1px solid #E5E5F6'
        }
      }
    },
    MuiButton: {}
  }
})
