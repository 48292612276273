import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Link,
  OutlinedInput
} from '@mui/material'
import { CloudPay, ErrorText, MButton, Text } from 'components'
import { MESSAGES, ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { APIs, type IVerifyPayBill } from 'services'
import * as yup from 'yup'

const schema = yup
  .object({
    accountId: yup.string().required('Account number is required'),
    lastName: yup.string().required('Last Name/Business Name is required')
  })
  .required()


export const Form: React.FC = () => {
  const [account, setAccount] = useState<string>('')
  const [cloudPayVisible, setCloudPayVisible] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IVerifyPayBill>({
    mode: 'all',
    defaultValues: {
      accountId: '',
      lastName: ''
    },
    resolver: yupResolver(schema)
  })
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (body: IVerifyPayBill): Promise<void> => {
    try {
      const response = await APIs.verifyPayBill(body)
      if (response.success === true) {
        setAccount(body.accountId)
        setCloudPayVisible(true)
      } else {
        enqueueSnackbar(MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
    }
  }

  const handleCloudPayClose = (): void => {
    setCloudPayVisible(false)
  }


  return (
    <>
      <Box>
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <FormLabel>Account Number</FormLabel>
            <Controller
              name="accountId"
              control={control}
              render={({ field: { onChange, value, name } }): JSX.Element => (
                <OutlinedInput
                  name={name}
                  placeholder="Enter your account number"
                  onChange={onChange}
                  value={value}
                  type="text"
                  fullWidth
                />
              )}
            />
            {(errors.accountId != null) && <ErrorText>{errors.accountId.message}</ErrorText>}
          </Box>
          <Box mb={2}>
            <FormLabel>Last Name/Business Name</FormLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value, name } }): JSX.Element => (
                <OutlinedInput
                  name={name}
                  placeholder="Enter your last name"
                  onChange={onChange}
                  value={value}
                  type="text"
                  fullWidth
                />
              )}
            />
            {(errors.lastName != null) && <ErrorText>{errors.lastName.message}</ErrorText>}
          </Box>

          <Box mt={3} mb={2} width="100%">
            <MButton
              variant="contained"
              type="submit"
              size="large"
              rounded="true"
              disabled={!isValid}
              texttransform="none"
              fullWidth
              onSubmit={handleSubmit(onSubmit)}
            >
              Pay Bill
            </MButton>

            <Box mt={3}>
              <Link href={ROUTES_PATH.SIGNIN} underline="none">
                <Text color="#1513C3" fontSize="16px" align="center">
                  Back to Login
                </Text>
              </Link>
            </Box>
          </Box>
        </form>
        {cloudPayVisible && (
          <CloudPay account={account} onClose={handleCloudPayClose} />
        )}
      </Box>
    </>
  )
}
